const allowedPromos = [
    { promoCode: "EXTRA10", eventName: "", discountType: "percent", discountValue: 10 },
    { promoCode: "SPEAKER", eventName: "", discountType: "percent", discountValue: 50 },
    { promoCode: "OFFER500", eventName: "INTERNATIONAL HRD SUMMIT", discountType: "fixed", discountValue: 200, ticketName: "Virtual Delegate Pass" },
    { promoCode: "TEBR10", eventName: "INTERNATIONAL HRD SUMMIT", discountType: "percent", discountValue: 10, ticketName: "Virtual Delegate Pass" },
    { promoCode: "PROMO24", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION", discountType: "fixed", discountValue: 200, ticketName: "Basic Package"},
    { promoCode: "PROMO24", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION", discountType: "fixed", discountValue: 200, ticketName: "Standard Package"},
    { promoCode: "OFFER999", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT CONFERENCE", discountType: "fixed", discountValue: 300},
    { promoCode: "TEBR10", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT CONFERENCE", discountType: "percent", discountValue: 10},
    { promoCode: "PASTPA", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT CONFERENCE", discountType: "fixed", discountValue: 600},
    { promoCode: "PA25V", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT CONFERENCE", discountType: "fixed", discountValue: 200},
    { promoCode: "INH24", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION", discountType: "fixed", discountValue: 500},
    { promoCode: "200OFF", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION", discountType: "fixed", discountValue: 200},
    {
        promoCode: "SPEAKER",
        eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT",
        discountType: "percent",
        discountValue: 0,
        ticketName: [
            "2-3 DELEGATES participating",
            "4+ DELEGATES participating"
        ]
    },
    { promoCode: "PROMO24", eventName: "4TH ANNUAL NURSE MANAGEMENT & PERSPECTIVES ON NURSING LEADERSHIP CONFERENCE", discountType: "fixed", discountValue: 200, ticketName: "Virtual Participation"},
    { promoCode: "PROMO25", eventName: "6TH ANNUAL BTEL SUMMIT & EXHIBITION", discountType: "fixed", discountValue: 200, ticketName: "IN-PERSON"},


];

const getDiscountFromPromo = (promoCode, eventName, ticketName) => {
    const lowercasePromoCode = promoCode.toLowerCase();
    const lowercaseEventName = eventName.toLowerCase();
    const lowercaseTicketName = ticketName.toLowerCase();
    const excludedKeyword = "recording";

    // Исключить билеты, содержащие слово "recording"
    if (lowercaseTicketName.includes(excludedKeyword)) {
        return null;
    }

    const promo = allowedPromos.find(
        (p) => p.promoCode.toLowerCase() === lowercasePromoCode &&
            (p.eventName.toLowerCase() === lowercaseEventName || p.eventName === "") &&
            (!p.ticketName || (Array.isArray(p.ticketName)
                ? p.ticketName.some((name) => name.toLowerCase() === lowercaseTicketName)
                : p.ticketName.toLowerCase() === lowercaseTicketName))
    );

    return promo ? { type: promo.discountType, value: promo.discountValue } : null;
};

// Пример вызова функции
const discount1 = getDiscountFromPromo("PA25V", "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT CONFERENCE", "Basic Package");
console.log(discount1); // { type: 'fixed', value: 200 }

const discount2 = getDiscountFromPromo("PA25V", "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT CONFERENCE", "Only Recording");
console.log(discount2);

export default getDiscountFromPromo;
