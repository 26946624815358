import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactPlayer from 'react-player';
import { isAfter, isBefore, parseISO } from 'date-fns';
import { useParams, useHistory, useLocation } from "react-router-dom";
import Footer from "../../components/website/Footer";
import Header from "../../components/website/Header";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleDown, faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons';
import { PayPalScriptProvider, PayPalButtons, BraintreePayPalButtons } from "@paypal/react-paypal-js";
import $ from "jquery";
import "magnific-popup";
import "swiper/css";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper/core';
SwiperCore.use([Navigation, Pagination]);
import 'swiper/css/pagination';
import api from "../../api/endpoints/events.js";
import storage from "../../firebase";
import { PDFViewer, pdf } from '@react-pdf/renderer';
import InvoicePDF from "../../components/InvoicePDF";
import Popup from "../../components/Popup";
import { NotificationManager } from 'react-notifications';
import { ThreeDots } from "react-loader-spinner";
import NotFound from "./NotFound";
import getPartnerUrl from "../../components/getPartnerUrl";
import PersonalPhotos from "../../components/PersonalPhotos";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import TvIcon from '@mui/icons-material/Tv';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import AssuredWorkloadRoundedIcon from '@mui/icons-material/AssuredWorkloadRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import Typography from '@mui/material/Typography';

import {
  listAll,
  ref as storageRef,
  getDownloadURL,
} from "firebase/storage";
import axios from "axios";
import keyImage1 from "../../assets/images/key1.png";
import keyImage2 from "../../assets/images/key2.png";
import keyImage3 from "../../assets/images/key3.png";
import keyImage4 from "../../assets/images/key4.png";
import keyImage5 from "../../assets/images/key5.png";
import keyImage6 from "../../assets/images/key6.png";

// import Rodal from "rodal";
import Modal from "../../components/Modal";

// include styles
import "rodal/lib/rodal.css";

// Import Swiper styles
import "swiper/css";

import { register } from "swiper/element/bundle";
import Breadcrumbs from "../../components/Breadcrumbs";
// import Timer from "../../components/Timer";
register();

const eventSettings = {
  eventEL: [
    { name: 'SUPER', startDate: '2024-01-01', endDate: '2024-06-01' },
    { name: 'BIRD', startDate: '2024-06-02', endDate: '2024-07-01' },
    { name: 'STANDARD', startDate: '2024-07-02', endDate: '2024-09-01' },
    { name: 'LAST', startDate: '2024-09-02', endDate: '2024-09-25' },
  ],
  eventAutomotive: [
    { name: 'BIRD', startDate: '2024-01-01', endDate: '2024-09-01' },
    { name: 'STANDARD', startDate: '2024-09-02', endDate: '2024-10-01' },
    { name: 'LAST', startDate: '2024-10-02', endDate: '2024-10-09' },
  ],
  eventLogistics: [
    { name: 'Pre-Registration', startDate: '2024-01-01', endDate: '2024-09-01' },
    { name: 'Bird', startDate: '2024-09-02', endDate: '2024-10-01' },
    { name: 'Standart', startDate: '2024-10-02', endDate: '2025-10-09' },
  ],
  default: 'Register',
};

function SingleConference() {

  const [buttonTexts, setButtonTexts] = useState({});
  const [sortedPaymentsTwo, setSortedPayments] = useState([]);
  const [sortedPaymentsForPayPal, setSortedPaymentsForPayPal] = useState([]);


  const handleButtonClick = (paymentName) => {
    handleOpenModal("delegate");
  };
  let location = useLocation();

  const createModalLink = (modalType) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('modal', modalType);
    return `${location.pathname}?${searchParams.toString()}`;
  };

  const marqueeRef = useRef();
  const [logos, setLogos] = useState([]);

  // const myEventList = ["7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE"];
  const myEventListButtons = ["5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE",
    "3RD ANNUAL NURSE MANAGEMENT & PERSPECTIVES ON NURSING LEADERSHIP HYBRID CONFERENCE",
    "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION", "5TH ANNUAL BTEL SUMMIT"];

  const myEventSpecific = ["ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION",
    "4TH ANNUAL NURSE MANAGEMENT & PERSPECTIVES ON NURSING LEADERSHIP CONFERENCE",
    "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT CONFERENCE", "6TH ANNUAL BTEL SUMMIT & EXHIBITION"];

  const myEventVideo = ["6TH ANNUAL BTEL SUMMIT & EXHIBITION"];
  const eventOnline = ["INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT"]

  const myEventDescription = ["5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT CONFERENCE"]

  const eventBTEL = ["6TH ANNUAL BTEL SUMMIT & EXHIBITION"]
  const eventPlantBased = ["INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT"]
  const eventNurse = ["4TH ANNUAL NURSE MANAGEMENT & PERSPECTIVES ON NURSING LEADERSHIP CONFERENCE"]
  const eventEL = ["4TH ANNUAL EXRACTABLE AND LEACHABLE ONLINE CONFERENCE"]
  const eventCleaning = ["THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE", "5TH ANNUAL BTEL SUMMIT & EXHIBITION"]

  const eventOnlinePa = ["EXECUTIVE AND PERSONAL ASSISTANT ONLINE CONFERENCE"]

  const  eventInhalation = ["7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION"]

  const eventPA = ["5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT"]
  const eventAdvancedPlastic = ["ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION"]

  const eventLogistics = ["LOGISTICS TECH SUMMIT"]
  const eventFAMS = ["FUTURE AUTOMOTIVE MATERIALS SUMMIT"]
  const eventGEN = ["3RD ANNUAL GENOTOXIC IMPURITIES SUMMIT: BEYOND NITROSAMINES"];
  const eventAutomotive = ["AUTOMOTIVE DISPLAY TECHNOLOGIES & HMI 2024 ONLINE CONFERENCE"];

  const galleryDestroyer = ["6TH ANNUAL BTEL SUMMIT & EXHIBITION", "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION",
    "3RD ANNUAL GENOTOXIC IMPURITIES SUMMIT: BEYOND NITROSAMINES"]

  const myEventBopap = ["ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION"]
  const allEvents = [""];

  const swiperRef = useRef(null);

  const speakerNamesToInclude = ["David Svoboda"];

  const handleImageClick = (index) => {
    console.log('Clicked on image with index:', index);
    const mainSwiper = document.getElementById('mainSwiper');
    if (mainSwiper) {
      mainSwiper.swiper.slideTo(index);
    }};

  const handleImageClickhotel = (index) => {
    console.log('Clicked on image with index:', index);
    const hotelSwiper = document.getElementById('hotelSwiper');
    if (hotelSwiper) {
      hotelSwiper.swiper.slideTo(index);
    }};
  const handleImageClickpartner = (index) => {
    console.log('Clicked on image with index:', index);
    const partnersSwiper = document.getElementById('partnersSwiper');
    if (partnersSwiper) {
      partnersSwiper.swiper.slideTo(index);
    }};


  const invoiceData = {
    invoiceNumber: 'INV123',
    date: '2023-08-24',
    // Add more data as needed
  };
  const { id } = useParams();
  const [conference, setConference] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [hotelImages, setHotelsImages] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const [isValidId, setIsValidId] = useState(true);
  const [delegatePopupOpened, setDelegatePopupOpened] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  // const [isBankTransferAvailable, setIsBankTransferAvailable] = useState(false);
  const [isPayPalDataFormVisible, setIsPayPalDataFormVisible] = useState(true);
  const [isCreditCardFormValid, setIsCreditCardFormValid] = useState(false);

  const [isFormValid, setIsFormValid] = useState(false);

  const agendaInitState = {
    conferenceName: "",
    fullName: "",
    email: "",
    phone: "",
    job: "",
    company: "",
    howHeard: "",
  };

  const bankInitState = {
    invoiceNum: "DEFAULT_NUMBER",
    conferenceName: "",
    firstName: "",
    lastName: "",
    email: "",
    job: "",
    company: "",
    address: "",
    city: "",
    vat: "",
    phone: "",
    ticket: { name: "", price: "" },
    promo: "",
  };

  const payPalInitState = {
    invoiceNum: "DEFAULT_NUMBER",
    conferenceName: "",
    firstName: "",
    lastName: "",
    email: "",
    job: "",
    company: "",
  };

  const creditCardInitState = {
    invoiceNum: "DEFAULT_NUMBER",
    conferenceName: "",
    firstName: "",
    lastName: "",
    email: "",
    job: "",
    company: "",
  };

  const [agenda, setAgenda] = useState(agendaInitState);
  const [bank, setBank] = useState(bankInitState);
  const [payPal, setPayPal] = useState(payPalInitState);
  const [creditCard, setCreditCard] = useState(creditCardInitState);
  const [currentType, setCurrentType] = useState(null);

  const handleAgenda = (event) => {
    const { name, value } = event.target;
    setAgenda((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleBank = (event) => {
    const { name, value } = event.target;
    const [paymentName, price] = value.split(':');
    setBank((prevState) => ({
      ...prevState,
      [name]: value,
      conferenceName: conference.eventName,
      ticket: { name: paymentName, price },
    }));
  };

  // const handleCreditCard = (event) => {
  //   const { name, value } = event.target;
  //   setCreditCard((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //     conferenceName: conference.eventName,
  //     from: "stripe btel"
  //   }));
  //
  //   validatePayPalForm({
  //     ...payPal,
  //     [name]: value,
  //   });
  // };






  const handlePayPal = (event) => {
    const { name, value } = event.target;
    setPayPal((prevState) => ({
      ...prevState,
      [name]: value,
      conferenceName: conference.eventName,
      from: "paypal btel"
    }));

    validatePayPalForm({
      ...payPal,
      [name]: value,
    });
  };

  const validatePayPalForm = (data) => {
    const { firstName, lastName, email, job, company } = data;
    const isValid =
        firstName.trim() !== '' &&
        lastName.trim() !== '' &&
        email.trim() !== '' &&
        job.trim() !== '' &&
        company.trim() !== '';
    setIsFormValid(isValid);
  };


  const handlePhoneChange = (phoneValue) => {
    setBank((prevState) => ({
      ...prevState,
      phone: phoneValue,
    }));
  };

  const handleAgendaPhoneChange = (phoneValue) => {
    setAgenda((prevState) => ({
      ...prevState,
      phone: phoneValue,
    }));
  };


  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  }

  const changeButton = (e) => {
    console.log(e.target.checked);
    setIsChecked(e.target.checked);
  };

  function handleSubmit(e) {
    e.preventDefault(); // Предотвращаем стандартное поведение формы (перезагрузку страницы)

    // Выполняем логику обработки данных формы
    // Например, отправка данных на сервер или другие действия

    // После обработки данных, вы можете перенаправить пользователя или выполнить другие действия
  }

  const handlePayPalSubmit = async (e) => {
    e.preventDefault();

    // Отправляем данные в Slack
    await postMessageToSlack(e, "pay-pal");

    // После успешной отправки переключаемся на вторую таблицу
    setIsPayPalDataFormVisible(false);
  };

  const handleCreditCardSubmit = async (e) => {
    e.preventDefault(); // Предотвращаем стандартное поведение формы

    // Отправляем данные на Slack
    await postMessageToSlack(e, "credit-card");

    // Переходим на страницу Stripe
    window.location.href = `/stripe/${conference.slug}`;
  };

  function handleFormSubmit(e) {
    e.preventDefault(); // Предотвращаем стандартное поведение формы (перезагрузку страницы)

    // Выполняем логику отправки сообщения в Slack
    postMessageToSlack(e, "agenda");

    // Выполняем логику обработки данных формы или другие действия
    handleSubmit(e);
  }
  const keyImages = [
    keyImage1,
    keyImage2,
    keyImage3,
    keyImage4,
    keyImage5,
    keyImage6,
  ];

  const createOrder = (data, actions, price) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: price, // Use the passed price value
          },
        },
      ],
    });
  };

  const onApprove = (data, actions) => {
    // This function is called when the payment is approved
    return actions.order.capture().then(details => {
      console.log('Payment successful', details);
    });
  };

  const imageCache = new Map();

  const fetchImages = async (listRef) => {
    try {
      if (imageCache.has(listRef)) {
        return imageCache.get(listRef);
      }
      const result = await listAll(listRef);
      const urlPromises = result.items.map(async (imageRef) => {
        const imageUrl = await getDownloadURL(imageRef);
        return imageUrl;
      });

      const urls = await Promise.all(urlPromises);
      imageCache.set(listRef, urls);
      return urls;
    } catch (error) {
      console.log('Error fetching images: ', error);
      throw error;
    }
  };

  const generateInvoice = async (bank, invoiceNum) => {
    try {
      console.log('bank before generate pdf: ', bank);
      const pdfBlob = await InvoicePDF(bank, invoiceNum);
      return pdfBlob; // Return the FormData containing the PDF
    } catch (error) {
      console.error('Error generating invoice:', error);
      throw error; // Re-throw the error to be handled in the higher-level function
    }
  };


  const sendToAPI = async (type, url) => {

    let bankName = {
      "Conference": bank.conferenceName,
      "First Name": bank.firstName,
      "Last Name": bank.lastName,
      "Email": bank.email,
      "Job Title": bank.job,
      "Company": bank.company,
      "Company Address": bank.address,
      "City": bank.city,
      "VAT": bank.vat,
      "Phone": bank.phone,
      "Ticket Type": { "Ticket Name": bank.ticket.name, "Ticket Price": bank.ticket.price },
      "Promo": bank.promo
    }

    const invoiceNumber = await api.get(`${url}User/invoice/get-number`);
    const inNum = invoiceNumber.data.toString();
    console.log('invoice number: ', inNum);
    setBank((prevState) => ({
      ...prevState,
      invoiceNum: inNum,
    }));

    setIsLoading(true);

    try {

      const pdfBlob = await generateInvoice(bank, inNum);
      const formData = new FormData();
      formData.append('data', JSON.stringify(bankName));
      formData.append('pdfFile', pdfBlob, 'invoice.pdf');

      console.log('bankData is: ', bankName);

      const response = await axios.post(`${url}User/slack-pdf`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      console.log('response from endpoint:', response);
      setIsModalOpen(false);
      document.querySelector(".header").style.zIndex = "3";
      if(type === "agenda") {
        NotificationManager.success('Thank you for your request!\n', 'Success', 5000);
      }
      else {
        NotificationManager.success('Thank you for your registration!\n' +
            'The invoice has been sent to your email.', 'Success', 5000);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error sending request to API:', error);
      setIsLoading(false);
      // Handle error as needed
    }
  };

  const sendPayPalToAPI = async (type, url) => {
    let payPalData = {
      "From": "paypal btel",
      "Conference": payPal.conferenceName,
      "First Name": payPal.firstName,
      "Last Name": payPal.lastName,
      "Email": payPal.email,
      "Job Title": payPal.job,
      "Company": payPal.company,
      "Promo": payPal.promo
    };

    try {
      const response = await axios.post(`${url}User/slack/${type}`, payPalData);
      console.log('PayPal data sent:', response);
    } catch (error) {
      console.error("Error sending PayPal data to Slack:", error);
    }
  };



  const sendCreditCardToAPI = async (type, url) => {
    let creditCardData = {
      "From": "stripe btel", // Указываем источник данных
      "Conference": creditCard.conferenceName,
      "First Name": creditCard.firstName,
      "Last Name": creditCard.lastName,
      "Email": creditCard.email,
      "Job Title": creditCard.job,
      "Company": creditCard.company,
      "Promo": creditCard.promo
    };

    try {
      const response = await axios.post(`${url}User/slack/${type}`, creditCardData);
      console.log('Credit Card data sent:', response);
    } catch (error) {
      console.error("Error sending Credit Card data to Slack:", error);
    }
  };


  const fetchPdf = async (eventId) => {
    try {
      const pdfRef = storageRef(storage, `gs://sns-cz.appspot.com/Agenda/${eventId}.pdf`);
      const pdfUrl = await fetchPdfUrl(pdfRef);
      return pdfUrl;
    } catch (error) {
      console.log('Error fetching PDF: ', error);
    }
  };

  const fetchPdfUrl = async (pdfRef) => {
    try {
      const pdfUrl = await getDownloadURL(pdfRef);
      return pdfUrl;
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    let isMounted = true; // Переменная для отслеживания монтирования компонента

    const fetchData = async () => {
      try {
        window.scrollTo(0, 0);

        const { data } = await api.get(`/Event/get-event-by-slug/${id}`);
        if (data.endDate == null) {
          if (isMounted) setIsValidId(false);
          return;
        }
        if (isMounted) {
          setConference(data);
          console.log('Conference id: ', data);

          setAgenda((prevState) => ({
            ...prevState,
            conferenceName: data.eventName,
          }));

          const conferenceGalleryListRef = storageRef(storage, `gs://sns-cz.appspot.com/Images/${data.id}`);
          const hotelId = data.hotels[0]?.id;
          const hotelGalleryListRef = storageRef(storage, `gs://sns-cz.appspot.com/Images/${hotelId}`);
          const [conferenceGalleryImages, hotelGalleryImages] = await Promise.all([
            fetchImages(conferenceGalleryListRef),
            fetchImages(hotelGalleryListRef),
          ]);

          // Установка изображений в состоянии
          setGalleryImages(conferenceGalleryImages);
          setHotelsImages(hotelGalleryImages);
          console.log('Gallery images: ', conferenceGalleryImages);
          console.log('Hotel images: ', hotelGalleryImages);
        }
      } catch (error) {
        console.log('Error fetching data: ', error);
        if (isMounted) setIsValidId(false);
      }
    };
    fetchData();

    return () => {
      isMounted = false; // Устанавливаем флаг в false при размонтировании
    };
  }, [id]);

  useEffect(() => {
    if (!conference) return;

    const newButtonTexts = {};
    const validPayments = [];
    const validPaymentsForPayPal = [];

    const getPaymentDateRange = (paymentName, conditions) => {
      for (const condition of conditions) {
        if (paymentName.includes(condition.name) && (!condition.exclude || !paymentName.includes(condition.exclude))) {
          return { startDate: parseISO(condition.startDate), endDate: parseISO(condition.endDate) };
        }
      }
      return null;
    };

    const applyEventSettings = (eventConditions) => {
      conference.payPalPayments?.forEach(payment => {
        const currentDate = new Date();
        const paymentDateRange = getPaymentDateRange(payment.paymentName, eventConditions);
        let text = eventSettings.default;

        if (paymentDateRange) {
          if (isAfter(currentDate, paymentDateRange.endDate)) {
            text = "Time Expired";
          } else if (isBefore(currentDate, paymentDateRange.startDate)) {
            text = "Coming Soon";
          } else {
            text = "Register";
          }
        }

        if (text === "Register" || text === "Coming Soon") {
          validPayments.push(payment);
          validPaymentsForPayPal.push(payment);
        }

        newButtonTexts[payment.paymentName] = text;
      });
    };

    if (eventEL.includes(conference.eventName)) {
      applyEventSettings(eventSettings.eventEL);
    } else if (eventAutomotive.includes(conference.eventName)) {
      applyEventSettings(eventSettings.eventAutomotive);
    } else if (eventLogistics.includes(conference.eventName)) {
      applyEventSettings(eventSettings.eventLogistics);
    } else {
      conference.payPalPayments?.forEach(payment => {
        newButtonTexts[payment.paymentName] = eventSettings.default;
        validPayments.push(payment);
        validPaymentsForPayPal.push(payment);
      });
    }

    setButtonTexts(newButtonTexts);
    setSortedPayments(validPayments);
    setSortedPaymentsForPayPal(validPaymentsForPayPal);
  }, [conference, eventEL, eventAutomotive, eventLogistics]);


  useEffect(() => {
    const marqueeElement = marqueeRef.current;

    if (marqueeElement) {
      const handleAnimationEnd = () => {
        // Когда анимация завершится, вы можете сделать что-то, если это необходимо
      };

      marqueeElement.addEventListener('animationend', handleAnimationEnd);

      // Очистка слушателя событий при размонтировании компонента
      return () => {
        marqueeElement.removeEventListener('animationend', handleAnimationEnd);
      };
    }

    return undefined;
  }, [marqueeRef]);

  function getSponsorType(sponsorType) {
    switch (sponsorType) {
      case 0:
        return <span>Diamond</span>;
      case 1:
        return <span>Platinum</span>;
      case 2:
        return <span>Gold</span>;
      case 3:
        return <span>Silver</span>;
      case 4:
        return <span>Keynote</span>;
      case 5:
        return <span>Exhibition</span>;
      case 6:
        return <span>Online Silver</span>;
        // Добавьте другие случаи по мере необходимости
      default:
        return <span>Другой тип спонсора</span>;
    }
  }
  useEffect(() => {
    $(".popup-with-zoom-anim").magnificPopup({
      type: "inline",
      fixedContentPos: false,
      fixedBgPos: true,
      overflowY: "auto",
      closeBtnInside: false,
      closeMarkup:
          '<button title="%title%" type="button" class="mfp-close close-button"> <div class="line1"></div> <div class="line2"></div> </button>',
      preloader: false,
      midClick: true,
      removalDelay: 300,
      autoOpen: false,
      mainClass: "my-mfp-fade-in",
      callbacks: {
        close: function () {
          $("html").removeClass("mfp-open");
        },
      },
    });
  });

  useEffect(() => {
    const overlay = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const popUps = document.querySelector('.modal, .mfp-bg');
          if (popUps) {
          } else {
            document.body.style.overflow = 'auto';
          }
        }
      }
    });

    overlay.observe(document.body, { childList: true, subtree: true });

    return () => overlay.disconnect();
  }, []);

  const formateDate = (date) => {
    if (!date.startDate || !date.endDate) {
      return '';
    }

    const { startDate, endDate } = date;
    const formattedDate = new Date(startDate);
    const [startDay, endDay] = [
      formattedDate.getDate(),
      new Date(endDate).getDate(),
    ];
    return ` ${formattedDate.toLocaleString("en-US", { month: "long" })}
     ${startDay} -
     ${endDay}, ${new Date(endDate).getFullYear()}`;
  };

  const postMessageToSlack = async (e, type) => {
    e.preventDefault();
    let url = '';
    if (window.location.hostname === 'localhost') {
      url = 'https://api.bcf-events.com/';
      // url = 'https://localhost:7264/';
    } else if (['green-desert-021865b10.3.azurestaticapps.net', 'brave-dune-00ae5aa10.3.azurestaticapps.net', 'new.bcf-events.com', 'bcf-events.com'].includes(window.location.hostname)) {
      url = 'https://api.bcf-events.com/';
    }

    try {
      if (type === "agenda") {
        let agendaName = {
          "Conference": agenda.conferenceName,
          "Full Name": agenda.fullName,
          "Email": agenda.email,
          "Phone": agenda.phone,
          "Job Title": agenda.job,
          "Company": agenda.company,
          "How did you hear about us?": agenda.howHeard,
        };

        if (window.location.href.includes('specific-conference/advanced-plastics-recycling-conference-and-christmas-industry-celebration')) {
          agendaName["Referer"] = "Рекламная ссылка";
        }

        console.log('URL for API:', url);
        console.log('Agenda Name:', agendaName);

        setAgenda(agendaInitState); // Сбрасываем состояние формы

        const response = await axios.post(`${url}User/slack/${type}`, agendaName);
        console.log('Response from endpoint:', response);

        const anchor = document.createElement("a");
        const urlpdf = await fetchPdf(conference.id);
        anchor.href = urlpdf;
        anchor.download = "agenda.pdf";
        anchor.style.display = "none";
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      } else {
        await sendToAPI(type, url);
      }

      if (type === "pay-pal") {
        await sendPayPalToAPI(type, url);
      } else if (type === "credit-card") {
        await sendCreditCardToAPI(type, url);
      }
    } catch (error) {
      console.error("Error sending message to Slack:", error);
    }
  };


  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = (type) => {
    setCurrentType(type);
    setIsModalOpen(true);
    if (type === "delegate") {
      setDelegatePopupOpened(true);
      gtag('event', 'conversion', {
        'send_to': 'AW-16507053678/pcC6CKCyyZ4ZEO7Mlr89',
        'value': 1.0,
        'currency': 'CZK',
        'event_category': 'Modal Open',
        'event_label': 'Delegate Modal Opened'
      });
    }
    document.querySelector(".header").style.zIndex = "1";
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    document.querySelector(".header").style.zIndex = "3";
    history.replace(location.pathname);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const modalType = searchParams.get('modal');
    if (modalType) {
      handleOpenModal(modalType);
    } else {
      // Закрыть модальное окно, если параметр 'modal' удален из URL
      setIsModalOpen(false);
    }
  }, [location.search]); /// Depend on location.search to re-evaluate when URL search params change

  // Existing useEffect
  useEffect(() => {
    if (isModalOpen) {
      // Additional logic when modal is open
    }
  }, [isModalOpen]);

  useEffect(() => {
    const listItems = document.querySelectorAll('.thisStyle, .andThisStyle');

    listItems.forEach(item => {
      const line = item.querySelector('.line-breadcrumbs');
      if(line) {
        line.style.width = item.offsetWidth + 'px';
      }
    });
  }, []);

  const [diffInSeconds, setDiffInSeconds] = useState(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      const endDate = new Date(conference.endDate);

      const diffInMilliseconds = endDate.getTime() - now.getTime();
      const calculatedDiffInSeconds = Math.floor(diffInMilliseconds / 1000);

      setDiffInSeconds(calculatedDiffInSeconds * 1000);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [conference.endDate]);

  if (!isValidId) {
    return <NotFound />;
  }
  const scrollToSection = (id, offset) => {
    const element = document.getElementById(id);
    if (element) {
      const yOffset = offset || 0; // Смещение, если не указано, будет равно 0
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 750); // Устанавливаем состояние isSticky в true, если прокрутка больше 500 пикселей
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // const handleOpenBankTransfer = () => {
  //   NotificationManager.error('Thank you for your request! This payment type does not work at this time. Use another one or write to us.', 'We are sorry', 5000);
  // }
  const [hasActiveFeature, setHasActiveFeature] = useState(false);

  useEffect(() => {
    console.log("Conference Name:", conference.eventName);
    console.log(Array.isArray(eventEL), typeof conference.eventName);
    console.log("Event Lists:", { eventInhalation, eventAutomotive, eventGEN, eventBTEL, eventEL, eventPA, eventLogistics, eventAdvancedPlastic, eventCleaning, eventFAMS });
    // Проверяем, активируется ли какая-либо из функций
    const isActive = eventInhalation.includes(conference.eventName) ||
        eventAutomotive.includes(conference.eventName) ||
        eventGEN.includes(conference.eventName) ||
        eventNurse.includes(conference.eventName) ||
        eventBTEL.includes(conference.eventName) ||
        eventEL.includes(conference.eventName) ||
        eventPA.includes(conference.eventName) ||
        eventPlantBased.includes(conference.eventName) ||
        eventLogistics.includes(conference.eventName) ||
        eventAdvancedPlastic.includes(conference.eventName) ||
        eventCleaning.includes(conference.eventName) ||
        eventFAMS.includes(conference.eventName);

    setHasActiveFeature(isActive);
  }, [conference.eventName]);

  const history = useHistory();
  const handleOpenSponsorship = () => {
    history.push("/sponsorship");
  };

  const handleSortOptions = (payments, eventName) => {
    if (eventName === "4TH ANNUAL NURSE MANAGEMENT & PERSPECTIVES ON NURSING LEADERSHIP CONFERENCE") {
      const order = ["Basic Package", "Standard Package", "GROUP OF 2 DELEGATES", "Virtual Participation"];

      // Сортируем опции согласно заданному порядку
      return payments.sort((a, b) => {
        let indexA = order.indexOf(a.paymentName);
        let indexB = order.indexOf(b.paymentName);

        // Если элемент не найден, ставим его в конец списка
        if (indexA === -1) indexA = order.length + 1;
        if (indexB === -1) indexB = order.length + 1;

        return indexA - indexB;
      });
    }
    if (eventName === "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT") {
      const order = ["1 DELEGATE", "2+ DELEGATES", "VIRTUAL", "RECORDING ONLY"];

      // Сортируем опции согласно заданному порядку
      return payments.sort((a, b) => {
        let indexA = order.indexOf(a.paymentName);
        let indexB = order.indexOf(b.paymentName);

        // Если элемент не найден, ставим его в конец списка
        if (indexA === -1) indexA = order.length + 1;
        if (indexB === -1) indexB = order.length + 1;

        return indexA - indexB;
      });
    }
    if (eventName === "LOGISTICS TECH SUMMIT") {
      const order = ["Pre-Registration Price", "Early Bird Price", "Standart Price"];

      // Сортируем опции согласно заданному порядку
      return payments.sort((a, b) => {
        let indexA = order.indexOf(a.paymentName);
        let indexB = order.indexOf(b.paymentName);

        // Если элемент не найден, ставим его в конец списка
        if (indexA === -1) indexA = order.length + 1;
        if (indexB === -1) indexB = order.length + 1;

        return indexA - indexB;
      });
    }
    if (eventName === "3RD ANNUAL GENOTOXIC IMPURITIES SUMMIT: BEYOND NITROSAMINES") {
      const order = ["Delegate Pass End-User", "Solution Provider", "Post Materials"];

      // Сортируем опции согласно заданному порядку
      return payments.sort((a, b) => {
        let indexA = order.indexOf(a.paymentName);
        let indexB = order.indexOf(b.paymentName);

        // Если элемент не найден, ставим его в конец списка
        if (indexA === -1) indexA = order.length + 1;
        if (indexB === -1) indexB = order.length + 1;

        return indexA - indexB;
      });
    }
    if (eventName === "4TH ANNUAL EXRACTABLE AND LEACHABLE ONLINE CONFERENCE") {
      const order = ["SUPER EARLY BIRD", "EARLY BIRD", "STANDARD", "LAST CALL"];

      // Сортируем опции согласно заданному порядку
      return payments.sort((a, b) => {
        let indexA = order.indexOf(a.paymentName);
        let indexB = order.indexOf(b.paymentName);

        // Если элемент не найден, ставим его в конец списка
        if (indexA === -1) indexA = order.length + 1;
        if (indexB === -1) indexB = order.length + 1;

        return indexA - indexB;
      });
    }
    // Если конференция другая, возвращаем массив без изменений
    return payments;
  };


// Убедитесь, что conference и conference.eventName существуют
  const sortedPayments = conference && conference.eventName
      ? handleSortOptions(conference.payPalPayments, conference.eventName)
      : [];


  const getButtonStyle = (text) => {
    switch (text) {
      case "Time Expired":
        return { backgroundColor: '#d3d3d3', cursor: 'not-allowed' };
      case "Coming Soon":
        return { backgroundColor: '#1db6e8', cursor: 'not-allowed' }; // Светло-голубой для "Coming Soon"
      case "Register":
        return { backgroundColor: '#007bff', cursor: 'pointer' };
      default:
        return { backgroundColor: '#d3d3d3', cursor: 'not-allowed' };
    }
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPayPal((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Validate form after every change
    validateForm({
      ...payPal,
      [name]: value,
    });
  };

  const validateForm = (data) => {
    const { firstName, lastName, email, job, company } = data;
    // Проверяем, что все обязательные поля заполнены
    const isValid =
        firstName.trim() !== '' &&
        lastName.trim() !== '' &&
        email.trim() !== '' &&
        job.trim() !== '' &&
        company.trim() !== '';
    setIsFormValid(isValid);
  };


  const handleCreditCardChange = (e) => {
    const { name, value } = e.target;

    setCreditCard((prev) => {
      const updatedCreditCard = {
        ...prev,
        [name]: value,
        conferenceName: conference.eventName, // Добавляем имя конференции
        from: "stripe btel" // Добавляем значение "from"
      };
      validateCreditCardForm(updatedCreditCard); // Проверяем форму с обновленными данными
      return updatedCreditCard;
    });
  };

  const validateCreditCardForm = (data) => {
    const { firstName, lastName, email, job, company } = data;
    const isValid =
        firstName.trim() !== '' &&
        lastName.trim() !== '' &&
        email.trim() !== '' &&
        job.trim() !== '' &&
        company.trim() !== '';
    setIsCreditCardFormValid(isValid);
  };



  return (
      <div>
        <Header />
        {/*<div>*/}
        {/*  {myEventBopap.includes(conference.eventName) && (*/}

        {/*      <Popup handleOpenModal={handleOpenModal} delegatePopupOpened={delegatePopupOpened} />*/}

        {/*  )}*/}
        {/*</div>*/}
        <main>
          {/* <PDFViewer width="100%" height={500}>
        <InvoicePDF invoiceData={invoiceData} />
      </PDFViewer> */}
          <section name="main">
            <div className="main-container">
              <div
                  className="background-single"
                  style={{
                    backgroundImage: `url(${conference.eventImage})`,
                  }}
              ></div>
              <div className="background-block">
                <div className="container" id="main">
                  <div className="breadcrumbs">
                    <ul>
                      <li className="andThisStyle">
                        <Link className="conference-link" to="/">
                          MAIN
                        </Link>
                      </li>
                      <li className="thisStyle">{conference.eventName}</li>
                    </ul>
                    <Breadcrumbs conferenceName={conference.eventName} />
                  </div>
                  <div className="title">
                    <h1>{conference.eventName}</h1>
                    <span className="line-title"></span>
                    <span className="mobile-title-line"></span>
                    <p>
                      {formateDate(conference)} {(conference.location !== 'online' && conference.location !== 'ONLINE') && (
                        <>
                          <h3>
                            {conference.hotels && conference.hotels.length > 0 ?
                                conference.hotels.map((hotel) => `Venue: ${hotel.hotelName}`).join(', ')
                                :
                                "To be announced soon"
                            }
                          </h3>
                          {conference.location}
                          {(eventCleaning.includes(conference.eventName) || eventOnlinePa.includes(conference.eventName)) && (
                              <h3>ONLINE on the Bigmarker Platform</h3>
                          )}
                        </>
                    )}

                      {/* Рендерим информацию об онлайн платформе, если событие входит в список eventAutomotive */}
                      {(eventAutomotive.includes(conference.eventName) || myEventVideo.includes(conference.eventName)
                          || eventOnlinePa.includes(conference.eventName) || eventOnline.includes(conference.eventName)
                          || eventEL.includes(conference.eventName) || eventNurse.includes(conference.eventName)) && (
                          <h3>ONLINE on the Bigmarker Platform</h3>
                      )}
                    </p>
                  </div>
                  {eventBTEL.includes(conference.eventName) && (
                      <div className="second_title">
                        <ul className="second_ditle_list">
                          <h3>Summit Features:</h3>
                          <li>• Workshop</li>
                          <li>• Networking Activities</li>
                          <li>• Event Application</li>
                          <li>• Poster Session</li>
                        </ul>
                      </div>
                  )}
                  {eventInhalation.includes(conference.eventName) && (
                      <div className="second_title">
                        <ul className="second_ditle_list">
                          <h3>Conference Features:</h3>
                          <li>• Icebreaker Session</li>
                          <li>• Networking Activities</li>
                          <li>• Event Application</li>
                        </ul>
                      </div>
                  )}
                  {eventPA.includes(conference.eventName) && (
                      <div className="second_title">
                        <ul className="second_ditle_list">
                          <h3>Conference Features:</h3>
                          <li>• Certified pre-conference workshop by David Svoboda, Canva Verified Expert and TEDx speaker</li>
                          <li>• 2-day conference access</li>
                          <li>• Lunches</li>
                          <li>• Cocktail reception</li>
                          <li>• Prosecco round table</li>
                          <li>• Post Documentation</li>
                          <li>• Recording</li>
                        </ul>
                      </div>
                  )}
                  {eventLogistics.includes(conference.eventName) && (
                      <div className="second_title">
                        <ul className="second_ditle_list">
                          <h3>Summit Features:</h3>
                          <li>• Icebreaker Session</li>
                          <li>• Networking Activities</li>
                          <li>• Event Application</li>
                        </ul>
                      </div>
                  )}
                  {eventAdvancedPlastic.includes(conference.eventName) && (
                      <div className="second_title">
                        <ul className="second_ditle_list">
                          <h3>Conference Features:</h3>
                          <li>• Icebreaker Session</li>
                          <li>• Networking Activities</li>
                          <li>• Event Application</li>
                        </ul>
                      </div>
                  )}
                  {eventFAMS.includes(conference.eventName) && (
                      <div className="second_title">
                        <ul className="second_ditle_list">
                          <h3>Conference Features:</h3>
                          <li>• Icebreaker Session</li>
                          <li>• Networking Activities</li>
                          <li>• Event Application</li>
                        </ul>
                      </div>
                  )}

                  <div className="action-block block-icons-div">
                    <ul>
                      {!allEvents.includes(conference.eventName) && (
                          <li onClick={() => handleOpenModal("agenda")} className="download-agenda">
                      <span className="icon">
                        <img
                            src={require("../../assets/images/adgenda.png")}
                            alt=""
                        />
                      </span>
                            <p>
                              DOWNLOAD AGENDA
                            </p>
                          </li>  )}
                      {!allEvents.includes(conference.eventName) && (
                          <span className="line new-line-conference"></span>
                      )}
                      <li className="become-delegate" onClick={() => handleOpenModal("delegate")}>
                        <Link to={`?modal=delegate`} className="custom-link icon-link">
                        <span className="icon">
                          <img src={require("../../assets/images/delegate.png")} alt="" />
                        </span>
                        </Link>
                        <Link to={`?modal=delegate`} className="custom-link text-link">
                          <p>REGISTER NOW</p>
                        </Link>
                      </li>
                      {conference.linkedInUrl != null && (
                          <>
                            <span className="line new-line-conference"></span>
                            <li className="event-page">
                              <a className="popup-with-zoom-anim2" target="_blank" href={conference.linkedInUrl}>
                        <span className="icon">
                          <img
                              src={require("../../assets/images/event.png")}
                              alt=""
                          />
                        </span>
                                <p>EVENT PAGE</p>
                              </a>
                            </li>
                          </>
                      )}
                      <span className="line new-line-conference"></span>
                      <li className="become-sponsor">
                        {conference.confExpensivness === 1 ? (
                            <Link className="popup-with-zoom-anim2" to="/sponsorship">
                          <span className="icon">
                            <img src={require("../../assets/images/sponsor.png")} alt="" />
                          </span>
                              <p>BECOME A SPONSOR</p>
                            </Link>
                        ) : (
                            <Link className="popup-with-zoom-anim2" to="/secondsponsorship">
                          <span className="icon">
                            <img src={require("../../assets/images/sponsor.png")} alt="" />
                          </span>
                              <p>BECOME A SPONSOR</p>
                            </Link>
                        )}
                      </li>
                    </ul>
                    <Modal
                        types="delegate"
                        currentType={currentType}
                        isOpen={isModalOpen}
                        onClose={handleCloseModal}
                    >
                      <div id="delegate-dialog" className="special-style">
                        <div className="popup-container"></div>
                        {eventBTEL.includes(conference.eventName) && (
                        <div
                            className="conference-link conference-data-link"
                            onClick={() => {
                              setCurrentType('credit-card'); // Указываем тип модального окна
                              setIsModalOpen(true); // Открываем модальное окно
                            }}
                        >
                          <div className="popup-item item-1">
                            <span className="text-popup">CREDIT CARD</span>
                          </div>
                        </div>
                        )}
                        {!eventBTEL.includes(conference.eventName) && (
                            <Link className="conference-link conference-data-link" to={`/stripe/${conference.slug}`}>
                              <div className="popup-item item-1">
                                <span className="text-popup">CREDIT CARD</span>
                              </div>
                            </Link>
                        )}
                        {eventBTEL.includes(conference.eventName) && (
                        <div
                            className="conference-link conference-data-link"
                            onClick={() => {
                              setCurrentType('pay-pal'); // Указываем тип модального окна
                              setIsModalOpen(true); // Открываем модальное окно
                            }}
                        >
                          <div className="popup-item item-2">
                            <span className="text-popup">PAYPAL</span>
                          </div>
                        </div>
                        )}
                        {!eventBTEL.includes(conference.eventName) && (
                            <Link className="conference-link conference-data-link" to={createModalLink('pay-pal')}>
                              <div className="popup-item item-2">
                                <span className="text-popup">PAYPAL</span>
                              </div>
                            </Link>
                            )}
                        <Link className="conference-link conference-data-link" to={createModalLink('bank-transfer')}>
                          <div className="popup-item item-3">
                            <span className="text-popup">BANK TRANSFER</span>
                          </div>
                        </Link>
                      </div>
                    </Modal>
                  </div>

                  <Modal
                      types="agenda"
                      currentType={currentType}
                      isOpen={isModalOpen}
                      onClose={handleCloseModal}
                  >
                    <div className="white-square">
                      <div className="scroll-container-wrapper">
                        <div className="scroll-container">
                          <form
                              className="form-bank"
                              onSubmit={(e) => handleFormSubmit(e)}>
                            <div className="form-group">
                              <label
                                  htmlFor="firstName"
                                  className="first-name-class"
                              >
                                Full Name*
                              </label>
                              <input
                                  type="text"
                                  id="first-name-input"
                                  name="fullName"
                                  required
                                  value={agenda.firstName}
                                  onChange={(e) => handleAgenda(e)}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="email">Email*</label>
                              <input
                                  type="email"
                                  id="email-input"
                                  name="email"
                                  required
                                  value={agenda.email}
                                  onChange={(e) => handleAgenda(e)}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="phone">Phone*</label>
                              <PhoneInput
                                  type="tel"
                                  id="phone"
                                  name="phone"
                                  required
                                  value={agenda.phone}
                                  onChange={handleAgendaPhoneChange}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="job-title">Job Title*</label>
                              <input
                                  type="text"
                                  id="job-title-input"
                                  name="job"
                                  required
                                  value={agenda.job}
                                  onChange={(e) => handleAgenda(e)}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="company-name">Company Name*</label>
                              <input
                                  type="text"
                                  id="company-name-input"
                                  name="company"
                                  required
                                  value={agenda.company}
                                  onChange={(e) => handleAgenda(e)}
                              />
                            </div>
                            <div className="form-group">
                              <p>How did you hear about us?*</p>
                              <div className="radio-buttons">
                                <label className="radio-option">
                                  <input
                                      type="radio"
                                      name="howHeard"
                                      required
                                      value="LinkedIn" // Каждой радиокнопке нужно явно задать значение
                                      checked={agenda.howHeard === "LinkedIn"}
                                      onChange={(e) => handleAgenda(e)}
                                      key="LinkedIn"
                                  />
                                  LinkedIn
                                </label>
                                <label className="radio-option">
                                  <input
                                      type="radio"
                                      name="howHeard"
                                      required
                                      value="Media Partner" // Каждой радиокнопке нужно явно задать значение
                                      checked={agenda.howHeard === "Media Partner"}
                                      onChange={(e) => handleAgenda(e)}
                                      key="Media Partner"
                                  />
                                  Media Partner
                                </label>
                                <label className="radio-option">
                                  <input
                                      type="radio"
                                      name="howHeard"
                                      required
                                      value="Email"
                                      checked={agenda.howHeard === "Email"}
                                      onChange={(e) => handleAgenda(e)}
                                  />
                                  Email
                                </label>
                                <label className="radio-option">
                                  <input
                                      type="radio"
                                      name="howHeard"
                                      required
                                      value="Colleagues"
                                      checked={agenda.howHeard === "Colleagues"}
                                      onChange={(e) => handleAgenda(e)}
                                  />
                                  Colleagues
                                </label>
                                <label className="radio-option">
                                  <input
                                      type="radio"
                                      name="howHeard"
                                      required
                                      value="Other"
                                      checked={agenda.howHeard === "Other"}
                                      onChange={(e) => handleAgenda(e)}
                                  />
                                  Other
                                </label>
                              </div>
                            </div>

                            <button type="submit" className="canister-button">
                              {isLoading ? (
                                  <ThreeDots height="40" width="40" color="white" />
                              ) : (
                                  "Request"
                              )}
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </Modal>
                  {eventBTEL.includes(conference.eventName) && (
                      <Modal
                          types="credit-card"
                          currentType={currentType}
                          isOpen={isModalOpen}
                          onClose={handleCloseModal}
                      >
                        <div className="bank-style special-style">
                          <div className="white-square">
                            <div className="scroll-container-wrapper">
                              <div className="scroll-container add-mobile-wrapper">
                                <form className="form-bank" onSubmit={handleCreditCardSubmit}>
                                  <div className="form-group">
                                    <label htmlFor="first-name" className="first-name-class">First Name*</label>
                                    <input
                                        type="text"
                                        id="first-name"
                                        name="firstName"
                                        required
                                        value={creditCard.firstName}
                                        onChange={handleCreditCardChange}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="last-name">Last Name*</label>
                                    <input
                                        type="text"
                                        id="last-name"
                                        name="lastName"
                                        required
                                        value={creditCard.lastName}
                                        onChange={handleCreditCardChange}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="email">Email*</label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        required
                                        value={creditCard.email}
                                        onChange={handleCreditCardChange}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="job-title">Job Title*</label>
                                    <input
                                        type="text"
                                        id="job-title"
                                        name="job"
                                        required
                                        value={creditCard.job}
                                        onChange={handleCreditCardChange}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="company">Company*</label>
                                    <input
                                        type="text"
                                        id="company"
                                        name="company"
                                        required
                                        value={creditCard.company}
                                        onChange={handleCreditCardChange}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="promo">Promo Code</label>
                                    <input
                                        type="text"
                                        id="promo"
                                        name="promo"
                                        value={creditCard.promo}
                                        onChange={handleCreditCardChange}
                                    />
                                  </div>
                                  <button
                                      type="submit"
                                      className={`register-button ${isCreditCardFormValid ? 'active' : 'inactive'}`}
                                      disabled={!isCreditCardFormValid}
                                  >
                                    Register
                                  </button>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal>
                      )}

                  {eventBTEL.includes(conference.eventName) && (
                  <>
                    {isPayPalDataFormVisible ? (
                        <Modal
                            types="pay-pal"
                            currentType={currentType}
                            isOpen={isModalOpen}
                            onClose={handleCloseModal}
                        >
                          <div className="bank-style special-style">
                            <div className="white-square">
                              <div className="scroll-container-wrapper">
                                <div className="scroll-container add-mobile-wrapper">
                                  <form
                                      className="form-bank"
                                      onSubmit={handlePayPalSubmit}  // Используем нашу функцию для обработки формы
                                  >
                                    <div className="form-group">
                                      <label htmlFor="first-name" className="first-name-class">First Name*</label>
                                      <input
                                          type="text"
                                          id="first-name"
                                          name="firstName"
                                          required
                                          value={payPal.firstName}
                                          onChange={handlePayPal}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="last-name">Last Name*</label>
                                      <input
                                          type="text"
                                          id="last-name"
                                          name="lastName"
                                          required
                                          value={payPal.lastName}
                                          onChange={handlePayPal}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="email">Email*</label>
                                      <input
                                          type="email"
                                          id="email"
                                          name="email"
                                          required
                                          value={payPal.email}
                                          onChange={handlePayPal}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="job-title">Job Title*</label>
                                      <input
                                          type="text"
                                          id="job-title"
                                          name="job"
                                          required
                                          value={payPal.job}
                                          onChange={handlePayPal}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="company">Company*</label>
                                      <input
                                          type="text"
                                          id="company"
                                          name="company"
                                          required
                                          value={payPal.company}
                                          onChange={handlePayPal}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="promo">Promo Code</label>
                                      <input
                                          type="text"
                                          id="promo"
                                          name="promo"
                                          value={payPal.promo}
                                          onChange={handlePayPal}
                                      />
                                    </div>
                                    <button
                                        type="submit"
                                        className={`register-button ${isFormValid ? 'active' : 'inactive'}`}
                                        disabled={!isFormValid}
                                    >
                                      Register
                                    </button>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Modal>


                    ) : (
                        <Modal
                            types="pay-pal"
                            currentType={currentType}
                            isOpen={isModalOpen}
                            onClose={handleCloseModal}
                        >
                          <div>
                            {conference.payPalPayments && conference.payPalPayments.length > 0 && (
                                <div className="paypal-square">
                                  <div className="scroll-container-wrapper">
                                    <div className="scroll-container">
                                      {sortedPaymentsForPayPal.map((pay) => (
                                          <div key={pay.id} className="paypal-wrapper">
                                            <div>
                                              <h4>{pay.paymentName}</h4>
                                              <p className="price-paypal">1 x {pay.price} EUR</p>
                                            </div>
                                            <div className="paypal-width">
                                              <PayPalScriptProvider
                                                  options={{
                                                    clientId:
                                                        'AYoVCFKvcXHDj0pcFTGhsqYjaH4dJnWibwLvIda5WAlELIXpQcqUM_y37SpKrdhB_3ts2RiH6kpYwf1i',
                                                    currency: 'EUR',
                                                  }}
                                              >
                                                <PayPalButtons
                                                    createOrder={(data, actions) =>
                                                        createOrder(data, actions, pay.price)
                                                    }
                                                    onApprove={onApprove}
                                                    style={{ layout: 'horizontal' }}
                                                />
                                              </PayPalScriptProvider>
                                            </div>
                                          </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                            )}
                          </div>
                        </Modal>
                    )}
                  </>
                      )}
                  <Modal
                      types="bank-transfer"
                      currentType={currentType}
                      isOpen={isModalOpen}
                      onClose={handleCloseModal}
                  >
                    <div className="bank-style  special-style">
                      <div className="white-square">
                        <div className="scroll-container-wrapper">
                          <div className="scroll-container add-mobile-wrapper">

                            <form
                                className="form-bank"
                                onSubmit={(e) =>
                                    postMessageToSlack(e, "bank-transfer")
                                }
                            >
                              <div className="form-group">
                                <label
                                    htmlFor="first-name"
                                    className="first-name-class"
                                >
                                  First Name*
                                </label>
                                <input
                                    type="text"
                                    id="first-name"
                                    name="firstName"
                                    required
                                    value={bank.firstName}
                                    onChange={(e) => handleBank(e)}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="last-name">Last Name*</label>
                                <input
                                    type="text"
                                    id="last-name"
                                    name="lastName"
                                    required
                                    value={bank.lastName}
                                    onChange={(e) => handleBank(e)}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="email">Email*</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    required
                                    value={bank.email}
                                    onChange={(e) => handleBank(e)}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="job-title">Job Title*</label>
                                <input
                                    type="text"
                                    id="job-title"
                                    name="job"
                                    required
                                    value={bank.job}
                                    onChange={(e) => handleBank(e)}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="company-name">
                                  Company Name*
                                </label>
                                <input
                                    type="text"
                                    id="company-name"
                                    name="company"
                                    required
                                    value={bank.company}
                                    onChange={(e) => handleBank(e)}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="company-address">
                                  Company Address*
                                </label>
                                <input
                                    type="text"
                                    id="company-address"
                                    name="address"
                                    required
                                    value={bank.address}
                                    onChange={(e) => handleBank(e)}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="city">City*</label>
                                <input
                                    type="text"
                                    id="city"
                                    name="city"
                                    required
                                    value={bank.city}
                                    onChange={(e) => handleBank(e)}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="vat">
                                  VAT of the company (only for EU company)
                                </label>
                                <input
                                    type="text"
                                    id="vat"
                                    name="vat"
                                    value={bank.vat}
                                    onChange={(e) => handleBank(e)}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="phone">Phone*</label>
                                <PhoneInput
                                    type="tel"
                                    id="phone"
                                    name="phone"
                                    required
                                    value={bank.phone}
                                    onChange={handlePhoneChange}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="promo">Promo</label>
                                <input
                                    type="text"
                                    id="promo"
                                    name="promo"
                                    value={bank.promo}
                                    onChange={(e) => handleBank(e)}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="ticket-type">Ticket Type*</label>
                                <select
                                    id="ticket-type"
                                    name="ticket"
                                    required
                                    value={bank.ticket.name !== "" ? `${bank.ticket.name}:${bank.ticket.price}` : ""}
                                    onChange={(e) => handleBank(e)}
                                >
                                  <option value="">Select one</option>
                                  {sortedPaymentsTwo.map((payment, index) => (
                                      <option key={`option-${index}`} value={`${payment.paymentName}:${payment.price}`}>
                                        {`${payment.paymentName}: ${"€" + payment.price}`}
                                      </option>
                                  ))}
                                </select>
                              </div>
                              <div className="form-group1">
                                <label class="galochka">
                                  <input
                                      type="checkbox"
                                      className="custom-checkbox"
                                      onChange={handleCheckboxChange}
                                      checked={isChecked}
                                  />
                                  I agree with&nbsp;<a href="https://bcf-events.com/terms">Terms and Conditions</a>
                                </label>
                              </div>
                              <button
                                  type="submit"
                                  className={`register-button ${isChecked ? 'active' : 'inactive'}`}
                                  disabled={!isChecked}
                              >
                                {isLoading ? <ThreeDots height="40" width="40" color="white"/> : "Register"}
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal>
                  {!eventBTEL.includes(conference.eventName) && (
                  <Modal
                      types="pay-pal"
                      currentType={currentType}
                      isOpen={isModalOpen}
                      onClose={handleCloseModal}
                  >
                    <div
                        //id="paypal-dialog"
                        //className="zoom-anim-dialog mfp-hide my-custom-popup-styles"
                    >
                      {conference.payPalPayments && conference.payPalPayments.length > 0 && (
                          <div className="paypal-square">
                            <div className="scroll-container-wrapper">
                              <div className="scroll-container">
                                {sortedPaymentsForPayPal.map((pay) => (
                                    <div key={pay.id} className="paypal-wrapper">
                                      <div>
                                        <h4>{pay.paymentName}</h4>
                                        <p className="price-paypal">1 x {pay.price} EUR</p>
                                      </div>
                                      <div className="paypal-width">
                                        <PayPalScriptProvider options={{ clientId: "AYoVCFKvcXHDj0pcFTGhsqYjaH4dJnWibwLvIda5WAlELIXpQcqUM_y37SpKrdhB_3ts2RiH6kpYwf1i", currency: "EUR" }}>
                                          <PayPalButtons
                                              createOrder={(data, actions) => createOrder(data, actions, pay.price)}
                                              onApprove={onApprove}
                                              style={{ layout: "horizontal" }}
                                          />
                                        </PayPalScriptProvider>
                                      </div>
                                    </div>
                                ))}
                              </div>
                            </div>
                          </div>
                      )}
                    </div>
                  </Modal>
                      )}
                </div>
              </div>

              <nav className={`page-navigation-container ${isSticky ? 'sticky' : ''}`}>
                <ul className="page-navigation">
                  <li><h2>Page Navigation</h2></li>
                  <li><a onClick={() => scrollToSection('price')}>Register now</a></li>
                  {conference.speakers && conference.speakers.length > 0 && (
                      <li><a onClick={() => scrollToSection('speakers', -175)}>Speakers</a></li>  )}
                  <li><a onClick={() => scrollToSection('about', -175)}>Conference overview</a></li>
                  <li><a onClick={() => scrollToSection('key', -175)}>Key points</a></li>
                  {conference.sponsors && conference.sponsors.length > 0 && (
                      <li><a onClick={() => scrollToSection('sponsors', -175)}>Sponsors</a></li> )}
                  <li><a onClick={() => scrollToSection('gallery', -175)}>Gallery</a></li>
                  {conference.hotels && conference.hotels.length > 0 && (
                      <li><a onClick={() => scrollToSection('venue', -175)}>Venue</a></li>  )}
                  <li><a onClick={() => scrollToSection('contact', -175)}>Contact us</a></li>
                </ul>
              </nav>
            </div>
            {conference.hotelMap && conference.hotelMap.length > 0 &&(
                <div className="centered-block"
                     style={{ paddingBottom: 0, paddingTop: '25px' }}>
                  <a className="popup-with-zoom-anim2" target="_blank" href={conference.hotelMap}>
                    <button className="neon-button">
                      VIEW PRE-CONFERENCE TEASER
                    </button>
                  </a>
                </div>
            )}
          </section>

          {conference.speakers && conference.speakers.length > 0 && (
              <section
                  className={`marquee-section ${
                      eventBTEL.includes(conference.eventName) ? "btel-marquee-background" : ""
                  }`}
              >
                <div className="marquee-container">
                  <ul className="marquee">
                    {conference.speakers.map((speaker, index) => (
                        speaker.logo && speaker.logo.length > 0 && (
                            <li key={speaker.id || index} className="marquee-item">
                              <div className="marquee-item-wrapper">
                                <img src={speaker.logo} alt={`Logo ${index}`} />
                              </div>
                            </li>
                        )
                    ))}
                  </ul>
                </div>
              </section>
          )}


          {conference.speakers && conference.speakers.length > 0 && (
              <section
                  className={`section-speakers ${eventBTEL.includes(conference.eventName) ? "btel-speakers" : ""}`}
                  id="speakers"
              >
                <div className="container">
                  <h2 className={`title-about title-about-single ${eventBTEL.includes(conference.eventName) ? "btel-title" : ""}`}>
                    {eventBTEL.includes(conference.eventName)
                        ? "Advisory Board and Speakers"
                        : "Speakers"
                    }
                  </h2>
                  <div className="line-container">
                    <span className={`line-about ${eventBTEL.includes(conference.eventName) ? "btel-line" : ""}`}></span>
                  </div>
                  {myEventDescription.includes(conference.eventName) && (
                      <p className="description-special add-about">
                        The Advisory Board is composed of experienced and distinguished thought leaders from various industries.
                        This group plays a crucial role in advising the BCF on the strategic direction of the conference program,
                        offering recommendations for speakers, shaping content themes, and providing valuable insights into the research community.
                      </p>
                  )}

                  <ul className="grid-container">
                    {conference.speakers &&
                        conference.speakers.map((speaker) => (
                            <li className="grid-item" key={speaker.id}>
                              <div>
                                <a className="popup-with-zoom-anim speaker-style" href={`#small-dialog-${speaker.id}`}>
                                  <img src={`${speaker.profileImage}`} alt="" className="flip-img mobile-image" />
                                </a>
                              </div>
                              <p className={`name ${eventBTEL.includes(conference.eventName) ? "btel-name" : ""}`}>
                                {speaker.fullName}
                              </p>
                              <p className="position">{speaker.position}</p>
                              {myEventVideo.includes(conference.eventName) && (
                                  <p className="company">
                                    <a href={speaker.speakersUrlLogo} style={{ color: '#00005A', fontWeight: 'bold', textDecoration: 'none' }}>
                                      {speaker.company}
                                    </a>
                                  </p>
                              )}
                              <a href={speaker.speakersUrlLogo} target="_blank">
                                <img className="logo-company" src={speaker.logo} alt="" />
                              </a>
                              <div className="scroll-container-wrapper">
                                <div id={`small-dialog-${speaker.id}`} className="zoom-anim-dialog mfp-hide my-custom-popup-style scroll-container">
                                  <div className="popup-flex">
                                    <img className="popup-img" src={speaker.profileImage} alt="" />
                                    <div className="popup-direction">
                                      <p className={`name mobile-name ${eventBTEL.includes(conference.eventName) ? "btel-name" : ""}`}>
                                        {speaker.fullName}
                                      </p>
                                      <p className="position mobile-position">{speaker.position}</p>
                                      <p className="company mobile-company">
                                        <a href={speaker.speakersUrlLogo} style={{ color: '#00005A', fontWeight: 'bold', textDecoration: 'underline' }}>
                                          {speaker.company}
                                        </a>
                                      </p>
                                    </div>
                                  </div>
                                  <p className="popup-desc" dangerouslySetInnerHTML={{ __html: speaker?.description }}></p>
                                </div>
                              </div>
                            </li>
                        ))}
                  </ul>
                </div>
              </section>
          )}


          {eventPA.includes(conference.eventName) && (
              <div className="container">
                <section className="about-container" id="about">
                  <div className="line-container" style={{display: 'flex', flexDirection: 'column', marginTop: '5px', marginBottom: '-10px' }}>
                    <h3 className="title-about title-about-single" style={{ marginTop: '0', marginBottom: '-0px' }}>
                      Pre-Conference Workshop
                    </h3>
                    <span className="line-about"></span>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center'}} className="content-wrapper">
                    <div className="text-wrapper" >
                      <div>
                        <h2 style={{ textAlign: 'left', paddingLeft: '9%'}}>Mastering Presentation Design:</h2>
                        <p style={{ textAlign: 'left', paddingLeft: '9%', paddingTop: '20px' }}>• Enhancing Canva Skills</p>
                        <p style={{ textAlign: 'left', paddingLeft: '9%' }}>• Creating Effective Presentations</p>
                        <p style={{ textAlign: 'left', paddingLeft: '9%' }}>• Working with Templates</p>
                        <p style={{ textAlign: 'left', paddingLeft: '9%' }}>• Using AI to Overcome Creative Block</p>
                        <p style={{ textAlign: 'left', paddingLeft: '9%' }}>• Official Certificates After the Workshop</p>
                      </div>
                      <p style={{ textAlign: 'left', paddingRight: '9%', paddingLeft: '9%'}}>
                        To make the most of this experience, we recommend that you bring your own laptop to try out new skills and features.
                      </p>
                    </div>
                    <div style={{ flexShrink: 0, paddingTop: '120px', width: '35%', height: '100%' }} className="image-wrapper">
                      <ul className="grid-container-teaser">
                        {conference.speakers &&
                            conference.speakers
                                .filter((speaker) => {
                                  // Фильтрация по идентификаторам или именам спикеров
                                  return (
                                      speakerNamesToInclude.includes(speaker.fullName)
                                  );
                                })
                                .map((speaker) => (
                                    <li className="grid-item" key={speaker.id}>
                                      <div>
                                        <a className="popup-with-zoom-anim speaker-style"
                                           href={`#small-dialog-${speaker.id}`}
                                        >
                                          <img
                                              src={`${speaker.profileImage}`}
                                              alt=""
                                              className="flip-img mobile-image"
                                          />
                                        </a>
                                      </div>
                                      <p className="name">{speaker.fullName}</p>
                                      <p className="position">{speaker.position}</p>
                                      <p className="company">
                                        <a href={speaker.speakersUrlLogo}  style={{ color: '#00005A', fontWeight: 'bold', textDecoration: 'none' }}>
                                          {speaker.company}
                                        </a>
                                      </p>
                                      <img
                                          className="logo-company"
                                          src={speaker.logo}
                                          alt=""
                                          style={{ width: '30%', height: '30%', objectFit: 'cover' }}
                                      />
                                      <div className="scroll-container-wrapper">
                                        <div id={`small-dialog-${speaker.id}`} className="zoom-anim-dialog mfp-hide my-custom-popup-style scroll-container">
                                          <div className="popup-flex">
                                            <img className="popup-img" src={speaker.profileImage} alt="" />
                                            <div className="popup-direction">
                                              <p className="name mobile-name">{speaker.fullName}</p>
                                              <p className="position mobile-position">
                                                {speaker.position}
                                              </p>
                                              <p className="company mobile-company">
                                                <a href={speaker.speakersUrlLogo} style={{ color: '#00005A', fontWeight: 'bold', textDecoration: 'underline' }}>
                                                  {speaker.company}
                                                </a>
                                              </p>
                                            </div>
                                          </div>
                                          <p className="popup-desc" dangerouslySetInnerHTML={{ __html: speaker?.description }}></p>
                                        </div>
                                      </div>
                                    </li>
                                ))}
                      </ul>
                      {/*<img*/}
                      {/*    src={require("../../assets/images/david.png")}*/}
                      {/*    alt=""*/}
                      {/*    style={{ width: '100%', height: '100%', objectFit: 'cover' }}*/}
                      {/*/>*/}
                    </div>
                  </div>
                  <button
                      className="action-button-register"
                      onClick={() => handleOpenModal("agenda")}
                      style={{ padding: '10px 33px' }}
                  >
                    Learn now
                  </button>
                </section>
              </div>
          )}
          {!eventOnlinePa.includes(conference.eventName) && (
              <section
                  className={`section-register ${eventBTEL.includes(conference.eventName) ? "btel-register-background" : ""}`}
              >
                <div id="price" className="container">
                  {!eventBTEL.includes(conference.eventName) && (
                      <>
                        <h2 className="title-about title-packages">
                          How to Register?
                        </h2>
                        <div className="line-container">
                          <span className="line-about"></span>
                        </div>
                      </>
                  )}
                  <ul className="packages-container">
                    {conference?.payPalPayments?.filter(payment =>
                        !payment.paymentName.includes('Sponsorship') && !payment.paymentName.includes('SPONSORSHIP')
                    ).map((payment, index) => (
                        <li key={index} className={`packages-item-conference ${eventBTEL.includes(conference.eventName) ? 'btel-package' : ''}`}>
                          <h3 className="sponsor-title-conference">
                            {payment.paymentName.replace('participating', '').replace('Pass', '').trim()}
                          </h3>
                          <div className="line-container">
                            <span className={`line-sponsorship ${eventBTEL.includes(conference.eventName) ? "btel-line-sponsorship" : ""}`}></span>
                          </div>
                          <p className={`sponsor-price ${eventBTEL.includes(conference.eventName) ? "btel-price" : ""}`}>
                            {"€" + payment.price}
                          </p>
                          <button
                              onClick={() => handleOpenModal('delegate')}
                              disabled={buttonTexts[payment.paymentName] !== "Register"} // Делаем кнопку некликабельной, если не "Register"
                              className={`sponsors-button ${eventBTEL.includes(conference.eventName) ? "btel-button" : ""}`}
                              style={getButtonStyle(buttonTexts[payment.paymentName])}
                          >
                            {buttonTexts[payment.paymentName]}
                          </button>
                          <ul className={`sponsors-list ${eventBTEL.includes(conference.eventName) ? 'sponsors-list-btel' : ''}`}>
                            {eventCleaning.includes(conference.eventName) && (
                                <div className="second_title">
                                  {(
                                      (payment.paymentName.includes('DELEGATE') || payment.paymentName.includes('DELEGATES') ||
                                          payment.paymentName.includes('delegates') || payment.paymentName.includes('delegate') ||
                                          payment.paymentName.includes('Delegate')) &&
                                      !payment.paymentName.includes('Virtual')
                                  ) && (
                                      <ul className="second_ditle_list">
                                        <AccountBalanceRoundedIcon color="primary" fontSize="large" />
                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                        <li>2-Day In-Person Participation</li>
                                        <li>Speakers' Presentations</li>
                                        <li>Video Recording</li>
                                        <li>Networking Lunches</li>
                                        <li>Evening Reception</li>
                                      </ul>
                                  )}

                                  {payment.paymentName.includes('Recording') && (
                                      <ul className="second_ditle_list">
                                        <VideoLibraryIcon color="primary" fontSize="large" />
                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                        <li>Speakers' Presentations</li>
                                        <li>Video Recording</li>
                                      </ul>
                                  )}
                                  {payment.paymentName.includes('Virtual') && (
                                      <ul className="second_ditle_list">
                                        <TvIcon color="primary" fontSize="large" />
                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                        <li>2-Day Virtual Participation</li>
                                        <li>Speakers' Presentations</li>
                                        <li>Video Recording</li>
                                      </ul>
                                  )}
                                </div>
                            )}
                            {eventFAMS.includes(conference.eventName) && (
                                <div className="second_title">
                                  {payment.paymentName.includes('OEM') && (
                                      <ul className="second_ditle_list">
                                        <Typography variant="h6" style={{ color: 'burlywood', fontSize: '14px' }}>
                                          Price is available till 01.09.2024
                                        </Typography>
                                      </ul>
                                  )}
                                  {payment.paymentName.includes('Solution Provider') && (
                                      <ul className="second_ditle_list">
                                        <Typography variant="h6" style={{ color: 'burlywood', fontSize: '14px' }}>
                                          Price is available till 01.10.2024
                                        </Typography>
                                      </ul>
                                  )}
                                  {payment.paymentName.includes('Solution Provider') && (
                                      <ul className="second_ditle_list">
                                        <Typography variant="h6" style={{ color: 'burlywood', fontSize: '14px' }}>
                                          Price is available till 09.10.2024
                                        </Typography>
                                      </ul>
                                  )}
                                </div>
                            )}

                            {eventBTEL.includes(conference.eventName) && (
                                <div className="second_title">
                                  {( payment.paymentName.includes('IN-PERSON')
                                  ) && (
                                      <ul className="second_ditle_list">
                                        <AccountBalanceRoundedIcon className="includes-title" fontSize="large" />
                                        <h3 className="includes-title">This includes:</h3>
                                        <li className="includes-title">3-Day Delegate Pass</li>
                                        <li className="includes-title">Networking Lunches</li>
                                        <li className="includes-title">Post Materials with Recording 2025</li>
                                        <li className="includes-title">Pre-conference Networking (March 25, 2025)</li>
                                        <li className="includes-title">Prosecco Round Table</li>
                                        <li className="includes-title">Evening Reception</li>
                                      </ul>
                                  )}
                                  {payment.paymentName.includes('Recording') && (
                                      <ul className="second_ditle_list">
                                        <VideoLibraryIcon className="includes-title" fontSize="large" />
                                        <h3 className="includes-title">This includes:</h3>
                                        <li>Speakers' Presentations</li>
                                        <li>Video Recording</li>
                                      </ul>
                                  )}

                                  {payment.paymentName.includes('VIRTUAL') && (
                                      <ul className="second_ditle_list">
                                        <VideoLibraryIcon className="includes-title" fontSize="large" />
                                        <h3 className="includes-title">This includes:</h3>
                                        <li>3-Day Virtual Participation</li>
                                        <li>Speakers' Presentations</li>
                                        <li>Video Recording</li>
                                      </ul>
                                  )}
                                </div>
                            )}
                            {eventAutomotive.includes(conference.eventName) && (
                                <div className="second_title">
                                  {payment.paymentName.includes('EARLY') && (
                                      <ul className="second_ditle_list">
                                        <Typography variant="h6" style={{ color: 'burlywood', fontSize: '14px' }}>
                                          Price is available till 01.09.2024
                                        </Typography>
                                      </ul>
                                  )}
                                  {payment.paymentName.includes('STANDARD') && (
                                      <ul className="second_ditle_list">
                                        <Typography variant="h6" style={{ color: 'burlywood', fontSize: '14px' }}>
                                          Price is available till 01.10.2024
                                        </Typography>
                                      </ul>
                                  )}
                                  {payment.paymentName.includes('LAST') && (
                                      <ul className="second_ditle_list">
                                        <Typography variant="h6" style={{ color: 'burlywood', fontSize: '14px' }}>
                                          Price is available till 09.10.2024
                                        </Typography>
                                      </ul>
                                  )}
                                </div>
                            )}
                            {eventNurse.includes(conference.eventName) && (
                                <div className="second_title">
                                  {payment.paymentName.includes('Recording') && (
                                      <ul className="second_ditle_list">
                                        <br/>
                                        <VideoLibraryIcon color="primary" fontSize="large" />
                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                        <li>Speakers' Presentations</li>
                                        <li>Video Recording</li>
                                      </ul>
                                  )}
                                  {payment.paymentName.includes('Virtual') && (
                                      <ul className="second_ditle_list">
                                        <br/>
                                        <VideoLibraryIcon color="primary" fontSize="large" />
                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                        <li>2-Day Virtual Participation</li>
                                        <li>Speakers' Presentations</li>
                                        <li>Video Recording</li>
                                      </ul>
                                  )}
                                </div>
                            )}
                            {eventEL.includes(conference.eventName) && (
                                <div className="second_title">
                                  {payment.paymentName.includes('SUPER') && (
                                      <ul className="second_ditle_list">
                                        <Typography variant="h6" style={{ color: 'burlywood', fontSize: '14px' }}>
                                          Price is available till 01.06.2024
                                        </Typography>
                                      </ul>
                                  )}
                                  {(payment.paymentName.includes('BIRD') && !payment.paymentName.includes('SUPER')) && (
                                      <ul className="second_ditle_list">
                                        <Typography variant="h6" style={{ color: 'burlywood', fontSize: '14px' }}>
                                          Price is available till 01.07.2024
                                        </Typography>
                                      </ul>
                                  )}
                                  {payment.paymentName.includes('STANDARD') && (
                                      <ul className="second_ditle_list">
                                        <Typography variant="h6" style={{ color: 'burlywood', fontSize: '14px' }}>
                                          Price is available till 01.09.2024
                                        </Typography>
                                      </ul>
                                  )}
                                  {payment.paymentName.includes('LAST') && (
                                      <ul className="second_ditle_list">
                                        <Typography variant="h6" style={{ color: 'burlywood', fontSize: '14px' }}>
                                          Price is available till 25.09.2024
                                        </Typography>
                                      </ul>
                                  )}
                                </div>
                            )}
                            {eventGEN.includes(conference.eventName) && (
                                <div className="second_title">
                                  {(
                                          payment.paymentName.includes('BASIC')
                                      )
                                      && (
                                          <ul className="second_ditle_list">
                                            <AccountBalanceRoundedIcon color="primary" fontSize="large" />
                                            <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                            <li>2 Networking Lunches</li>
                                            <li>Speakers' Presentations</li>
                                            <li>Prosecco Round Table</li>
                                            <li>Cocktail Reception</li>
                                            <li>Ice Breaker Session (Day Before Conference)</li>
                                          </ul>
                                      )}

                                  {payment.paymentName.includes('VIP') && (
                                      <ul className="second_ditle_list">
                                        <VideoLibraryIcon color="primary" fontSize="large" />
                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                        <li>2 Days of The Conference</li>
                                        <li>2 Networking Lunches</li>
                                        <li>Speakers' Presentations</li>
                                        <li>Prosecco Round Table</li>
                                        <li>Cocktail Reception</li>
                                        <li>Ice Breaker Session</li>
                                        <li>2 Nights Accommodation</li>
                                      </ul>
                                  )}
                                  {payment.paymentName.includes('Recording') && (
                                      <ul className="second_ditle_list">
                                        <AccountBalanceRoundedIcon color="primary" fontSize="large" />
                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                        <li>Speakers' Presentations</li>
                                        <li>Video Recording</li>
                                      </ul>
                                  )}
                                </div>
                            )}
                            {eventInhalation.includes(conference.eventName) && (
                                <div className="second_title">
                                  {payment.paymentName.includes('ONLY RECORDING') && (
                                      <ul className="second_ditle_list">
                                        <AccountBalanceRoundedIcon color="primary" fontSize="large" />
                                        <p style={{color: 'burlywood'}}>You will receive the post-event materials within 10 working days after the conference</p>
                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                        <li>2 Days of The Conference</li>
                                        <li>2 Lunches</li>
                                        <li>Post Materials</li>
                                        <li>Recording</li>
                                        <li>Prosecco Round Table</li>
                                        <li>Cocktail Reception</li>
                                      </ul>
                                  )}
                                  {payment.paymentName.includes('Standard') && (
                                      <ul className="second_ditle_list">
                                        <AssuredWorkloadRoundedIcon color="primary" fontSize="large"/>
                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                        <li>2-Day In-Person Participation</li>
                                        <li>2 Networking Lunches</li>
                                        <li>Prosecco Round Table</li>
                                        <li>Cocktail Reception</li>
                                        <li>Ice Breaker Session (Day Before Conference)</li>
                                        <li>Speakers' Presentations</li>
                                        <li>Video Recording</li>
                                      </ul>
                                  )}
                                  {payment.paymentName.includes('VIP') && (
                                      <ul className="second_ditle_list">
                                        <CheckCircleOutlineRoundedIcon color="primary" fontSize="large"/>
                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                        <li>2-Day In-Person Participation</li>
                                        <li>2 Networking Lunches</li>
                                        <li>Prosecco Round Table</li>
                                        <li>Cocktail Reception</li>
                                        <li>Ice Breaker Session (Day Before Conference)</li>
                                        <li>Speakers' Presentations</li>
                                        <li>Video Recording</li>
                                        <li>Celebration Party</li>
                                        <li>2 Nights Accommodation</li>
                                      </ul>
                                  )}
                                  {payment.paymentName.includes('Virtual') && (
                                      <ul className="second_ditle_list">
                                        <p style={{color: 'burlywood'}}>Virtually on the 21st-22nd of February 2024 on the BigMarker platform.</p>
                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                        <li>Virtual Participation</li>
                                        <li>Speakers' Presentations</li>
                                        <li>Video Recording</li>
                                      </ul>
                                  )}
                                </div>
                            )}
                            {eventPA.includes(conference.eventName) && (
                                <div className="second_title">
                                  {payment.paymentName.includes('Person') || payment.paymentName.includes('Basic')  && (
                                      <ul className="second_ditle_list">
                                        <AccountBalanceRoundedIcon color="primary" fontSize="large" />
                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                        <li>2 Days of The Conference</li>
                                        <li>2 Lunches</li>
                                        <li>Post Materials</li>
                                        <li>Recording</li>
                                        <li>Prosecco round table</li>
                                        <li>Cocktail Reception</li>
                                      </ul>
                                  )}
                                  {payment.paymentName.includes('Standard') && (
                                      <ul className="second_ditle_list">
                                        <AssuredWorkloadRoundedIcon color="primary" fontSize="large"/>
                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                        <li>2 Days of The Conference</li>
                                        <li>2 Lunches</li>
                                        <li>Post Materials</li>
                                        <li>Recording</li>
                                        <li>Prosecco round table</li>
                                        <li>Cocktail Reception</li>
                                        <li>Certificated Workshop (day before conference)</li>
                                      </ul>
                                  )}
                                  {payment.paymentName.includes('VIP') && (
                                      <ul className="second_ditle_list">
                                        <CheckCircleOutlineRoundedIcon color="primary" fontSize="large"/>
                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                        <li>2 Days of The Conference</li>
                                        <li>2 Lunches</li>
                                        <li>Post Materials</li>
                                        <li>Recording</li>
                                        <li>Prosecco round table</li>
                                        <li>Cocktail Reception</li>
                                        <li>Certificated Workshop (day before conference)</li>
                                        <li>2 Nights Accommodation</li>
                                      </ul>
                                  )}
                                  {payment.paymentName.includes('Recording') && (
                                      <ul className="second_ditle_list">
                                        <VideoLibraryIcon color="primary" fontSize="large" />
                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                        <li>Speakers' Presentations</li>
                                        <li>Video Recording</li>
                                      </ul>
                                  )}
                                  {payment.paymentName.includes('Virtual') && (
                                      <ul className="second_ditle_list">
                                        <TvIcon color="primary" fontSize="large" />
                                        <h3 style={{color: 'burlywood'}}>This includes::</h3>
                                        <li>Virtual Participation</li>
                                        <li>Speakers' Presentations</li>
                                        <li>Video Recording</li>
                                      </ul>
                                  )}
                                </div>
                            )}
                            {eventLogistics.includes(conference.eventName) && (
                                <div className="second_title">
                                  {payment.paymentName.includes('Bird') && (
                                      <ul className="second_ditle_list">
                                        <Typography variant="h6" style={{ color: 'burlywood', fontSize: '14px' }}>
                                          Price is available till 25.09.2024
                                        </Typography>
                                        <br/>
                                        <VideoLibraryIcon color="primary" fontSize="large" />
                                        <h3 style={{color: 'burlywood'}}>Summit Features:</h3>
                                        <li>2 days of the conference</li>
                                        <li>Lunches</li>
                                        <li>Coffee breaks</li>
                                        <li>Recording</li>
                                        <li>Evening Reception</li>
                                      </ul>
                                  )}
                                  {payment.paymentName.includes('Standart') && (
                                      <ul className="second_ditle_list">
                                        {/*<VideoLibraryIcon color="primary" fontSize="large" />*/}
                                        <h3 style={{color: 'burlywood'}}>Summit Features:</h3>
                                        <li>2 days of the conference</li>
                                        <li>Lunches</li>
                                        <li>Coffee breaks</li>
                                        <li>Recording</li>
                                        <li>Evening Reception</li>
                                      </ul>
                                  )}
                                  {payment.paymentName.includes('Pre-Registration') && (
                                      <ul className="second_ditle_list">
                                        <Typography variant="h6" style={{ color: 'burlywood', fontSize: '14px' }}>
                                          Price is available till 1.09.2024
                                        </Typography>
                                        <br/>
                                        <VideoLibraryIcon color="primary" fontSize="large" />
                                        <h3 style={{color: 'burlywood'}}>Summit Features:</h3>
                                        <li>2 days of the conference</li>
                                        <li>Lunches</li>
                                        <li>Coffee breaks</li>
                                        <li>Recording</li>
                                        <li>Evening Reception</li>
                                      </ul>
                                  )}
                                </div>
                            )}
                            {eventAdvancedPlastic.includes(conference.eventName) && (
                                <div className="second_title">
                                  {payment.paymentName.includes('Person') || payment.paymentName.includes('Basic')  && (
                                      <ul className="second_ditle_list">
                                        <AccountBalanceRoundedIcon color="primary" fontSize="large" />
                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                        <li>2-Day Delegate Pass</li>
                                        <li>Post Materials with Recording</li>
                                        <li>Networking Lunches</li>
                                      </ul>
                                  )}
                                  {payment.paymentName.includes('Standard') && (
                                      <ul className="second_ditle_list">
                                        <AssuredWorkloadRoundedIcon color="primary" fontSize="large"/>
                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                        <li>2-Day Delegate Pass</li>
                                        <li>Post Materials with Recording</li>
                                        <li>Networking Lunches</li>
                                        <li>Pre-conference Networking (December 4, 2024)</li>
                                        <li>Pre-Dinner Aperitifs</li>
                                        <li>Christmas Boat Dinner</li>
                                      </ul>
                                  )}
                                  {payment.paymentName.includes('Virtual') && (
                                      <ul className="second_ditle_list">
                                        <CheckCircleOutlineRoundedIcon color="primary" fontSize="large"/>
                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                        <li>2-Day Virtual Participation</li>
                                        <li>Speakers' Presentation</li>
                                        <li>Video Recording</li>
                                      </ul>
                                  )}
                                  {payment.paymentName.includes('Recording') && (
                                      <ul className="second_ditle_list">
                                        <VideoLibraryIcon color="primary" fontSize="large" />
                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                        <li>Speakers' Presentations</li>
                                        <li>Video Recording</li>
                                      </ul>
                                  )}
                                </div>
                            )}
                            {eventPlantBased.includes(conference.eventName) && (
                                <div className="second_title">
                                  {payment.paymentName.includes('IN-PERSON') && (
                                      <ul className="second_ditle_list">
                                        <AccountBalanceRoundedIcon color="primary" fontSize="large" />
                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                        <li>2-Day In-Person Participation for 1 delegate</li>
                                        <li>Networking Lunches</li>
                                        <li>Speakers' Presentations</li>
                                        <li>Video Recording</li>
                                        <li>Prosecco Round Table</li>
                                        <li>Cocktail Reception</li>
                                        <li>Ice Breaker Session (Day Before Conference)</li>
                                      </ul>
                                  )}
                                  {payment.paymentName.includes('RECORDING') && (
                                      <ul className="second_ditle_list">
                                        <VideoLibraryIcon color="primary" fontSize="large" />
                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                        <li>Speakers' Presentations</li>
                                        <li>Video Recording</li>
                                      </ul>
                                  )}
                                  {payment.paymentName.includes('VIRTUAL') && (
                                      <ul className="second_ditle_list">
                                        <TvIcon color="primary" fontSize="large" />
                                        <h3 style={{color: 'burlywood'}}>This includes::</h3>
                                        <li>Virtual Participation</li>
                                        <li>Speakers' Presentations</li>
                                        <li>Video Recording</li>
                                      </ul>
                                  )}
                                </div>
                            )}
                            {eventFAMS.includes(conference.eventName) && (
                                <div className="second_title">
                                  {payment.paymentName.includes('Person') && (
                                      <ul className="second_ditle_list">
                                        <h3 style={{color: 'burlywood'}}>Summit Features:</h3>
                                        <li>Icebreaker Session</li>
                                        <li>Networking Activities</li>
                                        <li>Event Application</li>
                                      </ul>
                                  )}
                                </div>
                            )}
                            {!hasActiveFeature && (
                                <div className="second_title">
                                  {payment.paymentName.includes('Person') && (
                                      <ul className="second_ditle_list">
                                        <AccountBalanceRoundedIcon color="primary" fontSize="large" />
                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                        <li>In Person Participation</li>
                                        <li>Speakers' Presentations</li>
                                        <li>Video Recording</li>
                                        <li>Evening Reception</li>
                                      </ul>
                                  )}
                                  {(payment.paymentName.includes('DELEGATE') || payment.paymentName.includes('DELEGATES')
                                      || payment.paymentName.includes('delegates') || payment.paymentName.includes('delegate')) && (
                                      <ul className="second_ditle_list">
                                        <AssuredWorkloadRoundedIcon color="primary" fontSize="large"/>
                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                        <li>2 Days of The Conference</li>
                                        <li>2 Networking Lunches</li>
                                        <li>Speakers' Presentations</li>
                                        <li>Prosecco Round Table</li>
                                        <li>Cocktail Reception</li>
                                        <li>Ice Breaker Session (Day Before Conference)</li>
                                      </ul>
                                  )}
                                  {payment.paymentName.includes('Virtual') && (
                                      <ul className="second_ditle_list">
                                        <TvIcon color="primary" fontSize="large" />
                                        <h3 style={{color: 'burlywood'}}>This includes::</h3>
                                        <li>Virtual Participation</li>
                                        <li>Speakers' Presentations</li>
                                        <li>Video Recording</li>
                                      </ul>
                                  )}
                                  {payment.paymentName.includes('Recording') && (
                                      <ul className="second_ditle_list">
                                        <VideoLibraryIcon color="primary" fontSize="large" />
                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                        <li>Speakers' Presentations</li>
                                        <li>Video Recording</li>
                                      </ul>
                                  )}
                                </div>
                            )}
                          </ul>
                        </li>
                    ))}
                    {(eventBTEL.includes(conference.eventName) || eventGEN.includes(conference.eventName) ||
                        eventEL.includes(conference.eventName) || eventAutomotive.includes(conference.eventName)) && (
                        <li className={`packages-item-conference ${eventBTEL.includes(conference.eventName) ? 'btel-package' : ''}`}>
                          <h3 className="sponsor-title-conference">
                            SPONSORSHIP
                          </h3>
                          <div className="line-container">
                            <span className={`line-sponsorship ${eventBTEL.includes(conference.eventName) ? 'btel-line-sponsorship' : ''}`}></span>
                          </div>
                          {eventEL.includes(conference.eventName) && (
                              <p className={`sponsor-price ${eventBTEL.includes(conference.eventName) ? 'btel-price' : ''}`}>{"From €699"}</p>
                          )}
                          {eventAutomotive.includes(conference.eventName) && (
                              <p className={`sponsor-price ${eventBTEL.includes(conference.eventName) ? 'btel-price' : ''}`}>{"From €599"}</p>
                          )}
                          {!eventEL.includes(conference.eventName) && !eventAutomotive.includes(conference.eventName) && (
                              <ul className="sponsors-list">
                                <div className="line-container"></div>
                                <h3 className="includes-title" style={{ color: eventBTEL.includes(conference.eventName) ? '#FF4500' : 'burlywood' }}>
                                  This includes:
                                </h3>
                                {!eventAutomotive.includes(conference.eventName) && (
                                    <li className={eventBTEL.includes(conference.eventName) ? 'includes-title' : ''}>Online access</li>
                                )}
                                <li className={eventBTEL.includes(conference.eventName) ? 'includes-title' : ''}>
                                  Promo on social media
                                </li>
                                <li className={eventBTEL.includes(conference.eventName) ? 'includes-title' : ''}>
                                  Logo and URL placement
                                </li>
                                <li className={eventBTEL.includes(conference.eventName) ? 'includes-title' : ''}>
                                  Advert in the agenda
                                </li>
                                <li className={eventBTEL.includes(conference.eventName) ? 'includes-title' : ''}>
                                  Speaking Slot or Workshop
                                </li>
                                <li className={eventBTEL.includes(conference.eventName) ? 'includes-title' : ''}>
                                  List of Attendees
                                </li>
                              </ul>

                          )}
                          <button
                              onClick={handleOpenSponsorship}
                              className={`sponsors-button ${eventBTEL.includes(conference.eventName) ? 'btel-button' : ''}`}
                          >
                            Contact us
                          </button>
                          {!eventEL.includes(conference.eventName) && !eventAutomotive.includes(conference.eventName) && (
                              <h3 style={{ color: eventBTEL.includes(conference.eventName) ? '#FF4500' : 'burlywood' }}>
                                For pre-registration, we can offer a significant discount and the best possible conditions.
                              </h3>
                          )}
                        </li>
                    )}

                  </ul>

                </div>
              </section>
          )}
          <div className="container">
            <section className="about-container" id="about">
              <h2
                  className="title-about title-about-single"
                  style={eventBTEL.includes(conference.eventName) ? { color: '#313F51' } : {}}
              >
                About the {" "}
                {conference.conferenceType === 0 ? "conference" : "summit"}
              </h2>
              <div className="line-container">
                <span className={`line-about ${eventBTEL.includes(conference.eventName) ? "btel-line" : ""}`}></span>
              </div>

              <p className="description-about add-about" dangerouslySetInnerHTML={{ __html: conference.aboutEvent }}></p>
              {/*<div className="eventPhotos">*/}
              {/*  <p className={`description-about ${conference.speakers && conference.speakers.length > 0 ? 'add-about-active' : 'add-about'}`}*/}
              {/*     dangerouslySetInnerHTML={{ __html: conference.aboutEvent }}></p>*/}
              {/*  {conference.speakers && conference.speakers.length > 0 && (*/}
              {/*      <PersonalPhotos speakers={conference.speakers} />*/}
              {/*  )}*/}
              {/*</div>*/}
              {!myEventListButtons.includes(conference.eventName) && (
                  <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap', justifyContent: 'center' }}>
                    <button
                        className="action-button-register"
                        style={eventBTEL.includes(conference.eventName) ? { backgroundColor: '#D85121', color: '#ffffff' } : {}}
                        onClick={() => handleOpenModal("delegate")}
                    >
                      Register now
                    </button>
                    <button
                        className="action-button-agenda"
                        style={eventBTEL.includes(conference.eventName) ? { backgroundColor: '#D85121', color: '#ffffff' } : {}}
                        onClick={() => handleOpenModal("agenda")}
                    >
                      Download PDF agenda
                    </button>
                  </div>
              )}
              {conference.hotelMap && conference.hotelMap.length > 0 &&(
                  <a className="popup-with-zoom-anim2" target="_blank" href={conference.hotelMap}>
                    <button className="neon-button">
                      Free Pre-Summit Teaser
                    </button>
                  </a>

              )}
              {/*<button className="register-button"><ThreeDots  height="40" width="40" color="white"  /></button>*/}
            </section>
          </div>
          {myEventVideo.includes(conference.eventName) && (
              <section className="videobox">
                <div className="playerbox">
                  <div className="player">
                    <iframe width="1000" height="450" src="https://www.youtube.com/embed/id7iybrQrKg?si=egglYoPzj9nel3Ew" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                  </div>
                </div>
              </section>)}
          {eventAdvancedPlastic.includes(conference.eventName) && (
              <section className="videobox">
                <div className="playerbox">
                  <div className="player">
                    <iframe
                        src="https://www.youtube.com/embed/edqyedO4gRo?si=kyHx5K2tHoshzy1s"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                        className="responsive-iframe"
                    ></iframe>
                  </div>
                </div>
              </section>
          )}
          {galleryDestroyer.includes(conference.eventName) && (
              <section
                  className="section-gallery"
                  id="gallery"
                  style={eventBTEL.includes(conference.eventName) ? { backgroundColor: '#313F51' } : {}}
              >
                <div className="container">
                  <h2 className="title-gallery title-about-single">Gallery</h2>
                  <div className="line-container">
                    <span className={`line-about ${eventBTEL.includes(conference.eventName) ? "btel-line" : ""}`}></span>
                  </div>
                  <span className="opacity-gallery">124</span>
                </div>
                <Swiper
                    id="mainSwiper"
                    ref={(swiper) => (swiperRef.current = swiper)}
                    spaceBetween={10}
                    pagination={{ clickable: true }}
                    centeredSlides={true}
                    initialSlide={3}
                    navigation={{
                      nextEl: '.right-arrow',
                      prevEl: '.left-arrow',
                    }}
                    breakpoints={{
                      319: {
                        slidesPerView: 3
                      },
                      767: {
                        slidesPerView: 5
                      }
                    }}
                >
                  {galleryImages.map((image, index) => (
                      <React.Fragment>
                        <SwiperSlide key={image} onClick={() => handleImageClick(index)}>
                          <div
                              className="slide-swiper"
                              key={image}
                              style={{
                                backgroundImage: `url(${image})`,
                              }}
                          ></div>
                        </SwiperSlide>
                      </React.Fragment>
                  ))}
                </Swiper>
                <div className="arrow-container home-arrow-container single-arrow-container">
                  {/*<div className="left-arrow" id="btnPrev2"></div>*/}
                  {/*<div className="right-arrow" id="btnNext2"></div>*/}
                </div>

              </section>
          )}
          <section
              className="section-keys"
              id="key"
              style={eventBTEL.includes(conference.eventName) ? { backgroundColor: '#000000' } : {}}
          >
            <div className="key-container container">
              <div className="key-container-block">
                <h3 className="key-title">KEY POINTS</h3>
                <ul>
                  {conference.keyPoints?.map((point, index) => (
                      <div
                          key={point.id}
                          className={`key-block ${index > 2 && !showAll ? 'hidden' : ""}`}>
                        <img
                            src={`${keyImages[index % keyImages.length]}`}
                            alt=""
                        />
                        <li>{point.keyName}</li>
                      </div>
                  ))}
                  {conference.keyPoints?.length > 2 && (
                      <li className={`key-point-btn ${!showAll ? 'btn-hidden' : ""}`}>
                        <span onClick={() => setShowAll(!showAll)}>
                          {showAll ? <FontAwesomeIcon icon={faAngleDoubleUp} /> : <FontAwesomeIcon icon={faAngleDoubleDown} />}
                        </span>
                      </li>
                  )}
                </ul>
              </div>
              <span
                  className="vertical-line"
                  style={eventBTEL.includes(conference.eventName) ? { backgroundColor: '#D85121' } : {}}
              ></span>
              <div className="mobile-line-container">
                <span className="mobile-title-line"></span>
              </div>
              <div className="key-container-block">
                <h3 className="key-title">WHO SHOULD ATTEND</h3>
                <ul>
                  {conference.attendReasons?.map((reason, index) => (
                      <div
                          key={reason.id}
                          className={`key-block ${index > 2 && !showAll ? 'hidden' : ""}`}>
                        <img
                            src={`${keyImages[index % keyImages.length]}`}
                            alt=""
                        />
                        <li>{reason.reason}</li>
                      </div>
                  ))}
                  {conference.attendReasons?.length > 2 && (
                      <li className={`key-point-btn ${!showAll ? 'btn-hidden' : ""}`}>
                        <span onClick={() => setShowAll(!showAll)}>
                          {showAll ? <FontAwesomeIcon icon={faAngleDoubleUp} /> : <FontAwesomeIcon icon={faAngleDoubleDown} />}
                        </span>
                      </li>
                  )}
                </ul>
              </div>
            </div>
            <div style={{ display: 'flex', marginTop: '25px', justifyContent: 'center' }}>
              <button
                  className="action-button-register"
                  style={eventBTEL.includes(conference.eventName) ? { backgroundColor: '#D85121', color: '#ffffff' } : {}}
                  onClick={() => handleOpenModal("delegate")}
              >
                Register Now
              </button>
            </div>
          </section>
          {conference.sponsors && conference.sponsors.length > 0 && (
              <section className="section-speakers" id="sponsors">
                <div className="container">
                  <h2
                      className="title-about title-about-single"
                      style={eventBTEL.includes(conference.eventName) ? { color: '#D85121 !important', fontWeight: 'bold' } : {}}
                  >
                    {eventBTEL.includes(conference.eventName) ? 'Past Attendees' : 'Sponsors'}
                  </h2>

                  <div className="line-container">
                    <span className={`line-about ${eventBTEL.includes(conference.eventName) ? "btel-line" : ""}`}></span>
                  </div>
                  <ul className="grid-container-sponsor">
                    {conference.sponsors &&
                        conference.sponsors
                            .sort((a, b) => a.sponsorType - b.sponsorType) // Сортировка по типу спонсора
                            .map((sponsor) => (
                                <a href={sponsor.sponsorUrlLogo} key={sponsor.id} target="_blank" rel="noopener noreferrer">
                                  <li className="grid-item" key={sponsor.id} style={{ marginBottom: '-160px' }}>
                                    <div className="new-sponsors">
                                      <p
                                          className="name"
                                          style={eventBTEL.includes(conference.eventName) ? { backgroundColor: '#ffffff', color: '#D85121', fontSize: '22px' } : { fontSize: '22px' }}
                                      >
                                        {getSponsorType(sponsor.sponsorType)}
                                      </p>

                                      <img
                                          className="new-logo-sponsor"
                                          src={sponsor.sponsorLogo}
                                          alt=""
                                      />
                                    </div>
                                  </li>
                                </a>))}
                  </ul>
                </div>
                <div style={{ display: 'flex', marginTop: '25px', justifyContent: 'center' }}>
                  <Link className="popup-with-zoom-anim2" to="/secondsponsorship">
                    <button
                        className="action-button-register"
                        style={eventBTEL.includes(conference.eventName) ? { backgroundColor: '#D85121', color: '#ffffff' } : undefined}
                        onClick={() => handleOpenModal("delegate")}
                    >
                      Become a sponsor
                    </button>
                  </Link>
                </div>
              </section>
          )}
          {/*{conference.sponsors && conference.sponsors.length > 0 && (*/}
          {/*    <section className="section-sponsors">*/}
          {/*      <div className="container">*/}
          {/*        <div className="sponsors-wrapper">*/}
          {/*          {conference.sponsors.some(sponsor => sponsor.sponsorType === 0) && (*/}
          {/*              <div className="price-sponsors-container custom-sponsors">*/}
          {/*                <h3 className="price-sponsors">*/}
          {/*                  {conference.sponsors.some(sponsor => sponsor.sponsorType === 0) ? (*/}
          {/*                      conference.sponsors.filter(sponsor => sponsor.sponsorType === 0).length > 1 ? (*/}
          {/*                          'Diamond Sponsors'*/}
          {/*                      ) : (*/}
          {/*                          'Diamond Sponsor'*/}
          {/*                      )*/}
          {/*                  ) : null}*/}
          {/*                </h3>*/}
          {/*                <div className="line-container">*/}
          {/*                  <span className="line-about"></span>*/}
          {/*                </div>*/}
          {/*                <div className="sponsors-image">*/}
          {/*                {conference.sponsors*/}
          {/*                    .filter(sponsor => sponsor.sponsorType === 0)*/}
          {/*                    .map((sponsor, index) => (*/}
          {/*                      <a href={sponsor.sponsorUrlLogo} key={sponsor.id} target="_blank" rel="noopener noreferrer">*/}
          {/*                        <img*/}
          {/*                            src={sponsor.sponsorLogo}*/}
          {/*                            className="sponsor-image"*/}
          {/*                            key={sponsor.id}*/}
          {/*                        />*/}
          {/*                        </a>))}*/}
          {/*                </div>*/}
          {/*              </div>*/}
          {/*          )}*/}
          {/*          {conference.sponsors.some(sponsor => sponsor.sponsorType === 1) && (*/}
          {/*              <div className="price-sponsors-container custom-sponsors">*/}
          {/*                <h3 className="price-sponsors">*/}
          {/*                  {conference.sponsors.some(sponsor => sponsor.sponsorType === 1) ? (*/}
          {/*                      conference.sponsors.filter(sponsor => sponsor.sponsorType === 1).length > 1 ? (*/}
          {/*                          'Platinum Sponsors'*/}
          {/*                      ) : (*/}
          {/*                          'Platinum Sponsor'*/}
          {/*                      )*/}
          {/*                  ) : null}*/}
          {/*                </h3>*/}
          {/*                <div className="line-container">*/}
          {/*                  <span className="line-about"></span>*/}
          {/*                </div>*/}
          {/*                <div className="sponsors-image">*/}
          {/*                {conference.sponsors*/}
          {/*                    .filter(sponsor => sponsor.sponsorType === 1)*/}
          {/*                    .map((sponsor, index) => (*/}

          {/*                      <a href={sponsor.sponsorUrlLogo} key={sponsor.id} target="_blank" rel="noopener noreferrer">*/}
          {/*                        <img*/}
          {/*                            src={sponsor.sponsorLogo}*/}
          {/*                            className="sponsor-image"*/}
          {/*                            key={sponsor.id}*/}
          {/*                        />*/}
          {/*                        </a> ))}*/}
          {/*                </div>*/}

          {/*              </div>*/}
          {/*          )}*/}
          {/*          {conference.sponsors.some(sponsor => sponsor.sponsorType === 2) && (*/}
          {/*              <div className="price-sponsors-container custom-sponsors">*/}
          {/*                <h3 className="price-sponsors">*/}
          {/*                  {conference.sponsors.some(sponsor => sponsor.sponsorType === 2) ? (*/}
          {/*                      conference.sponsors.filter(sponsor => sponsor.sponsorType === 2).length > 1 ? (*/}
          {/*                          'Gold Sponsors'*/}
          {/*                      ) : (*/}
          {/*                          'Gold Sponsor'*/}
          {/*                      )*/}
          {/*                  ) : null}*/}
          {/*                </h3>*/}
          {/*                <div className="line-container">*/}
          {/*                  <span className="line-about"></span>*/}
          {/*                </div>*/}
          {/*                <div className="sponsors-wrapper">*/}
          {/*                  <div className="sponsors-image">*/}
          {/*                  {conference.sponsors*/}
          {/*                      .filter(sponsor => sponsor.sponsorType === 2)*/}
          {/*                      .map((sponsor, index) => (*/}
          {/*                        <a href={sponsor.sponsorUrlLogo} key={sponsor.id} target="_blank" rel="noopener noreferrer">*/}
          {/*                          <img*/}
          {/*                              src={sponsor.sponsorLogo}*/}
          {/*                              className="sponsor-image"*/}
          {/*                              key={sponsor.id}*/}
          {/*                          />*/}
          {/*                          </a>))}*/}
          {/*                  </div>*/}
          {/*                </div>*/}
          {/*              </div>*/}
          {/*          )}*/}
          {/*          {conference.sponsors.some(sponsor => sponsor.sponsorType === 3) && (*/}
          {/*              <div className="price-sponsors-container custom-sponsors">*/}
          {/*                <div className="price-sponsors-container">*/}
          {/*                  <h3 className="price-sponsors">*/}
          {/*                    {conference.sponsors.some(sponsor => sponsor.sponsorType === 3) ? (*/}
          {/*                        conference.sponsors.filter(sponsor => sponsor.sponsorType === 3).length > 1 ? (*/}
          {/*                            'Silver Sponsors'*/}
          {/*                        ) : (*/}
          {/*                            'Silver Sponsor'*/}
          {/*                        )*/}
          {/*                    ) : null}*/}
          {/*                  </h3>*/}
          {/*                  <div className="line-container">*/}
          {/*                    <span className="line-about"></span>*/}
          {/*                  </div>*/}
          {/*                  <div className="sponsors-image">*/}
          {/*                  {conference.sponsors*/}
          {/*                      .filter(sponsor => sponsor.sponsorType === 3)*/}
          {/*                      .map((sponsor, index) => (*/}
          {/*                        <a href={sponsor.sponsorUrlLogo} key={sponsor.id} target="_blank" rel="noopener noreferrer">*/}
          {/*                          <img*/}
          {/*                              key={sponsor.id}*/}
          {/*                              src={sponsor.sponsorLogo}*/}
          {/*                              className="sponsor-image"*/}
          {/*                          />*/}
          {/*                          </a>))}*/}
          {/*                  </div>*/}
          {/*                </div>*/}
          {/*              </div>*/}
          {/*          )}*/}
          {/*          {conference.sponsors.some(sponsor => sponsor.sponsorType === 6) && (*/}
          {/*              <div className="price-sponsors-container custom-sponsors">*/}
          {/*                <div className="price-sponsors-container">*/}
          {/*                  <h3 className="price-sponsors">*/}
          {/*                    {conference.sponsors.some(sponsor => sponsor.sponsorType === 6) ? (*/}
          {/*                        conference.sponsors.filter(sponsor => sponsor.sponsorType === 6).length > 1 ? (*/}
          {/*                            'Online Silver Sponsors'*/}
          {/*                        ) : (*/}
          {/*                            'Online Silver Sponsor'*/}
          {/*                        )*/}
          {/*                    ) : null}*/}
          {/*                  </h3>*/}
          {/*                  <div className="line-container">*/}
          {/*                    <span className="line-about"></span>*/}
          {/*                  </div>*/}
          {/*                  <div className="sponsors-image">*/}
          {/*                    {conference.sponsors*/}
          {/*                        .filter(sponsor => sponsor.sponsorType === 6)*/}
          {/*                        .map((sponsor, index) => (*/}
          {/*                            <a href={sponsor.sponsorUrlLogo} key={sponsor.id} target="_blank" rel="noopener noreferrer">*/}
          {/*                              <img*/}
          {/*                                  key={sponsor.id}*/}
          {/*                                  src={sponsor.sponsorLogo}*/}
          {/*                                  className="sponsor-image"*/}
          {/*                              />*/}
          {/*                            </a>))}*/}
          {/*                  </div>*/}
          {/*                </div>*/}
          {/*              </div>*/}
          {/*          )}*/}
          {/*          {conference.sponsors.some(sponsor => sponsor.sponsorType === 4) && (*/}
          {/*              <div className="price-sponsors-container custom-sponsors">*/}
          {/*                <div className="price-sponsors-container">*/}
          {/*                  <h3 className="price-sponsors">*/}
          {/*                    {conference.sponsors.some(sponsor => sponsor.sponsorType === 4) ? (*/}
          {/*                        conference.sponsors.filter(sponsor => sponsor.sponsorType === 4).length > 1 ? (*/}
          {/*                            'Keynote Sponsors'*/}
          {/*                        ) : (*/}
          {/*                            'Keynote Sponsor'*/}
          {/*                        )*/}
          {/*                    ) : null}*/}
          {/*                  </h3>*/}
          {/*                  <div className="line-container">*/}
          {/*                    <span className="line-about"></span>*/}
          {/*                  </div>*/}
          {/*                  <div className="sponsors-image">*/}
          {/*                  {conference.sponsors*/}
          {/*                      .filter(sponsor => sponsor.sponsorType === 4)*/}
          {/*                      .map((sponsor, index) => (*/}
          {/*                        <a href={sponsor.sponsorUrlLogo} key={sponsor.id} target="_blank" rel="noopener noreferrer">*/}
          {/*                          <img*/}
          {/*                              key={sponsor.id}*/}
          {/*                              src={sponsor.sponsorLogo}*/}
          {/*                              className="sponsor-image"*/}
          {/*                          />*/}
          {/*                          </a>))}*/}
          {/*                  </div>*/}
          {/*                </div>*/}
          {/*              </div>*/}
          {/*          )}*/}
          {/*          {conference.sponsors.some(sponsor => sponsor.sponsorType === 5) && (*/}
          {/*              <div className="price-sponsors-container custom-sponsors">*/}
          {/*                <div className="price-sponsors-container">*/}
          {/*                  <h3 className="price-sponsors">*/}
          {/*                    {conference.sponsors.some(sponsor => sponsor.sponsorType === 5) ? (*/}
          {/*                        conference.sponsors.filter(sponsor => sponsor.sponsorType === 5).length > 1 ? (*/}
          {/*                            'Exhibition Sponsors'*/}
          {/*                        ) : (*/}
          {/*                            'Exhibition Sponsor'*/}
          {/*                        )*/}
          {/*                    ) : null}*/}
          {/*                  </h3>*/}
          {/*                  <div className="line-container">*/}
          {/*                    <span className="line-about"></span>*/}
          {/*                  </div>*/}
          {/*                  <div className="sponsors-image">*/}
          {/*                  {conference.sponsors*/}
          {/*                      .filter(sponsor => sponsor.sponsorType === 5)*/}
          {/*                      .map((sponsor, index) => (*/}
          {/*                          <a href={sponsor.sponsorUrlLogo} key={sponsor.id} target="_blank" rel="noopener noreferrer">*/}
          {/*                            <img*/}
          {/*                                key={sponsor.id}*/}
          {/*                                src={sponsor.sponsorLogo}*/}
          {/*                                className="sponsor-image"*/}
          {/*                            />*/}
          {/*                          </a>))}*/}
          {/*                  </div>*/}
          {/*                </div>*/}
          {/*              </div>*/}
          {/*          )}*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </section>*/}
          {/*)}*/}

          {conference.partners && conference.partners.length > 0 && (
              <section name="partners" className="partners-block">
                <div className="container">
                  <h2
                      className="title-about title-about-single"
                      style={eventBTEL.includes(conference.eventName) ? { color: '#D85121' } : undefined}
                  >
                    Our partners
                  </h2>
                  <div className="line-container">
                    <span className="line-about"></span>
                  </div>

                  <Swiper
                      id="partnersSwiper"
                      ref={(swiper) => (swiperRef.current = swiper)}
                      spaceBetween={10}
                      centeredSlides={true}
                      className="partners-list"
                      navigation={{
                        nextEl: '.right-arrow-partners',
                        prevEl: '.left-arrow-partners',
                      }}
                      pagination={{ clickable: true }}
                      breakpoints={{
                        319: {
                          slidesPerView: 3,
                          centeredSlides: true,
                          initialSlide: 6
                        },
                        767: {
                          slidesPerView: 3,
                          centeredSlides: true,
                          spaceBetween: 5,
                          initialSlide: 6
                        },
                        1023: {
                          slidesPerView: 5,
                          centeredSlides: true,
                          initialSlide: 6
                        }

                      }}

                      onSlideChange={(swiper) => {
                        setActiveSlide(swiper.activeIndex);
                      }}>
                    {conference.partners?.map((partner, index) => (
                        <React.Fragment key={partner.id}>
                          <SwiperSlide onClick={() => handleImageClickpartner(index)}>
                            <a href={getPartnerUrl(partner, conference.eventName)} target="_blank" rel="noopener noreferrer">
                              <li className="your-existing-li-class" style={{ opacity: activeSlide === index ? 1 : 0.5 }}>
                                <img src={partner.partnerLogo} alt={partner.partnerName}/>
                              </li>
                            </a>
                          </SwiperSlide>
                        </React.Fragment>
                    ))}

                  </Swiper>
                  <div className="arrow-container home-arrow-container single-arrow-container">
                    {/*<div className="right-arrow-partners" id="btnNext2"></div>*/}
                    {/*<div className="left-arrow-partners" id="btnPrev2"></div>*/}
                  </div>
                </div>
              </section>
          )}
          {conference.hotels && conference.hotels.length > 0 && (
              <section className="venue-section" id="venue">
                <div className="container" style={{ marginBottom: '25px' }}>
                  <h2 className="title-gallery">Venue</h2>
                  <div className="line-container">
                    <span className="line-about"></span>
                  </div>
                  {conference?.hotels?.map((hotel) => (
                      <div key={hotel.id}>
                      <span>
                        <h3 className="venue-title">
                          {hotel.hotelName}
                          <br/>
                          {myEventDescription.includes(conference.eventName) && (
                              <span style={{ fontSize: 'medium', color: 'lightblue' }}>
                             Book with us and get a discounted price. Contact us at: {" "}
                                <a href={`mailto: general@bcf-events.com`} style={{color: "white", textDecoration: "underline" }}>
                                  general@bcf-events.com
                                      </a>
                              </span>
                          )}
                          </h3>
                         <p className="venue-description">
                          <span className="address-line">{hotel.hotelAddress}</span>
                           {/*<span className="blue-line"></span>*/}
                           {/*     <button onClick={() => window.open(conference.hotelMap, '_blank')} */}
                           {/*             target="_blank" className="how-to-get-button">How to get</button>*/}
                         </p>
                      </span>
                        {conference.hotelBookingUrl && conference.hotelBookingUrl.length > 0  && (
                            <div className="btn-wrapper">
                              <button onClick={() => window.open(conference.hotelBookingUrl, '_blank')} target="_blank" className="venue-btn">Book a room</button>
                            </div>)}
                      </div>))}
                  <span className="opacity-gallery">124</span>
                </div>
                <Swiper
                    id="hotelSwiper"
                    ref={(swiper) => (swiperRef.current = swiper)}
                    initialSlide={3}
                    spaceBetween={10}
                    centeredSlides={true}
                    navigation={{
                      nextEl: '.right-arrow-venue',
                      prevEl: '.left-arrow-venue',
                    }}
                    pagination={{ clickable: true }}
                    breakpoints={{
                      319: {
                        slidesPerView: 3
                      },
                      767: {
                        slidesPerView: 5
                      }
                    }}
                >
                  {hotelImages.map((image, index) => (
                      <React.Fragment>
                        <SwiperSlide key={image} onClick={() => handleImageClickhotel(index)}>
                          <div
                              className="slide-swiper"
                              key={image}
                              style={{
                                backgroundImage: `url(${image})`,
                              }}
                          ></div>
                        </SwiperSlide>
                      </React.Fragment>
                  ))}
                </Swiper>
                <div className="arrow-container home-arrow-container single-arrow-container">
                  {/*<div className="left-arrow-venue" id="btnPrev"></div>*/}
                  {/*<div className="right-arrow-venue" id="btnNext"></div>*/}
                </div>
              </section>
          )}
          {conference.employees && conference.employees.length > 0 && (
              <section name="employees" className="employees-block" id="contact">
                <div className="container">
                  <h2
                      className="title-about title-about-single"
                      style={eventBTEL.includes(conference.eventName) ? { color: '#313F51 !important' } : undefined}
                  >
                    Contact us
                  </h2>
                  <div className="line-container">
                    <span className={`line-about ${eventBTEL.includes(conference.eventName) ? "btel-line" : ""}`}></span>
                  </div>
                  <div>
                    <ul className="contact-list">
                      {conference.employees?.map((employee) => (
                          <li
                              key={employee.id}
                              style={eventBTEL.includes(conference.eventName) ? { color: '#D85121' } : undefined}
                          >
                            <h2>{employee.callToAction}</h2>
                            <img width="150px" src={employee.photo} alt="" />
                            <h3>{employee.fullName}</h3>
                            <h4>{employee.position}</h4>
                            <p className="phone">{employee.phone}</p>
                            <p className="email"><a  className="employee-mailto" href={`mailto:${employee.email}`}>{employee.email}</a></p>
                          </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </section>
          )}
        </main>
        {!eventBTEL.includes(conference.eventName) && <Footer />}
      </div>
  );
}
//ещё
export default SingleConference;